import { Flex, Link, Text, Heading } from '@radix-ui/themes'
import React, { FC } from 'react'

const NotFound: FC = () => {
    return (
        <Flex
            direction="column"
            justify="start"
            className="w-full h-full mx-4 my-16 sm:mx-6 sm:my-6"
        >
            <Flex direction="column" gap="4" className="mb-24">
                <Heading size="5" weight="medium">
                    Not Found
                </Heading>
                <Text weight="regular" className="w-full lg:w-[1000px]">
                    The page you are looking for does not exist. If you have any
                    questions, please feel free to reach out to me at{' '}
                    <Link href="mailto:gabriel@pricetracker.cc" size="3">
                        support
                    </Link>
                </Text>
            </Flex>
        </Flex>
    )
}

export default NotFound

import { Flex, Heading, Link, Text } from '@radix-ui/themes'
import { FC } from 'react'
import Faq from '../../shared/components/FAQs/Faq'
import ReactGA from 'react-ga4'

const Faqs: FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/faq',
        title: 'FAQs',
    })
    return (
        <Flex
            direction="column"
            justify="start"
            className="w-full h-full mx-4 my-16 sm:mx-6 sm:my-6"
        >
            <Flex direction="column" gap="4" className="mb-24">
                <Heading size="5" weight="medium">
                    FAQs
                </Heading>
                <Text weight="regular" className="w-full lg:w-[1000px]">
                    Welcome to the Frequently Asked Questions page. Find answers
                    to common questions about PriceTracker. If you have any
                    other questions, please feel free to reach out to me at{' '}
                    <Link href="mailto:gabriel@pricetracker.cc" size="3">
                        support
                    </Link>
                </Text>
            </Flex>
            <Flex
                align="center"
                className=" h-full w-full"
                gap="9"
                direction="column"
                id="Pricing"
            >
                <Faq />
            </Flex>
        </Flex>
    )
}

export default Faqs

import React from 'react'
import { FC } from 'react'
import { Flex, Button, Dialog, Box, IconButton } from '@radix-ui/themes'
import LoginForm from '../Form/Auth/LoginForm'
import { useLoginDialog, useSignupDialog } from '../../hooks'
import { Cross1Icon } from '@radix-ui/react-icons'

interface LoginDialogProps {}

const LoginDialog: FC<LoginDialogProps> = ({}) => {
    const { loginDialog, openLoginDialog, closeLoginDialog } = useLoginDialog()
    const { openSignupDialog } = useSignupDialog()
    return (
        <Dialog.Root
            open={loginDialog}
            onOpenChange={loginDialog ? closeLoginDialog : openLoginDialog}
        >
            <Dialog.Content maxWidth="450px" className=" overflow-visible">
                {loginDialog && (
                    <Box>
                        <LoginForm onSuccess={() => closeLoginDialog()} />
                        <Flex
                            align="center"
                            justify="center"
                            className="w-full"
                        >
                            <Button
                                variant="ghost"
                                onClick={() => {
                                    closeLoginDialog()
                                    openSignupDialog()
                                }}
                            >
                                Signup
                            </Button>
                        </Flex>
                    </Box>
                )}
                <Dialog.Close className="absolute top-0 right-0 z-20 translate-x-1/4 -translate-y-1/4 bg-[var(--gray-3)] opacity-95 shadow-md text-[var(--gray-12)]">
                    <IconButton
                        variant="ghost"
                        radius="full"
                        className="color-black"
                    >
                        <Cross1Icon />
                    </IconButton>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Root>
    )
}

export default LoginDialog

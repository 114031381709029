import * as React from 'react'
import classNames from 'classnames'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { Text } from '@radix-ui/themes'
import { Theme } from '@radix-ui/themes'
import {
    ComponentPropsWithout,
    extractProps,
    RemovedProps,
} from '@radix-ui/themes/dist/cjs/helpers'
import {
    TooltipOwnProps,
    tooltipPropDefs,
} from '@radix-ui/themes/dist/cjs/props'

type TooltipElement = React.ElementRef<typeof TooltipPrimitive.Content>
interface TooltipProps
    extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root>,
        ComponentPropsWithout<
            typeof TooltipPrimitive.Content,
            RemovedProps | 'content'
        >,
        TooltipOwnProps {
    content: React.ReactNode
    container?: React.ComponentPropsWithoutRef<
        typeof TooltipPrimitive.Portal
    >['container']
}
const Tooltip = React.forwardRef<TooltipElement, TooltipProps>(
    (props, forwardedRef) => {
        const {
            children,
            className,
            defaultOpen,
            delayDuration,
            disableHoverableContent,
            content,
            container,
            forceMount,
            ...tooltipContentProps
        } = extractProps(props, tooltipPropDefs)
        const rootProps = {
            defaultOpen,
            delayDuration,
            disableHoverableContent,
        }
        const onOpenChange = (openState: boolean) => {
            setOpen(openState)
        }
        const [open, setOpen] = React.useState(false)

        return (
            <TooltipPrimitive.Root
                onOpenChange={onOpenChange}
                open={open}
                {...rootProps}
            >
                <TooltipPrimitive.Trigger asChild>
                    <span onClick={() => setOpen((prev) => !prev)}>
                        {children}
                    </span>
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal
                    container={container}
                    forceMount={forceMount}
                >
                    <Theme asChild>
                        <TooltipPrimitive.Content
                            sideOffset={4}
                            collisionPadding={10}
                            {...tooltipContentProps}
                            asChild={false}
                            ref={forwardedRef}
                            className={classNames(
                                'rt-TooltipContent',
                                className
                            )}
                        >
                            <Text as="p" className="rt-TooltipText" size="1">
                                {content}
                            </Text>
                            <TooltipPrimitive.Arrow className="rt-TooltipArrow" />
                        </TooltipPrimitive.Content>
                    </Theme>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        )
    }
)
Tooltip.displayName = 'Tooltip'

export { Tooltip }
export type { TooltipProps }

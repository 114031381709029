import { z } from 'zod'

export const CreateTrackerSchema = z.object({
    productUrl: z.string().url('Invalid URL').min(1, 'URL is required'),
    trackerName: z.string().optional(),
    currentPrice: z.union([
        z
            .string()
            .min(1, 'Price is required')
            .refine((value) => /^[0-9.]*$/.test(value), {
                message: 'Price must only contain numbers and dots',
            })
            .refine(
                (value) => {
                    // Value must be convertible to a number
                    const num = Number(value)
                    return !isNaN(num)
                },
                { message: 'Price must be a valid number' }
            ),
        z.number(),
    ]),
    targetPrice: z.union([
        z
            .string()
            .refine((value) => /^[0-9.]*$/.test(value), {
                message: 'Price must only contain numbers and dots',
            })
            .refine(
                (value) => {
                    // Value must be convertible to a number
                    const num = Number(value)
                    return !isNaN(num)
                },
                { message: 'Price must be a valid number' }
            ),
        z.number(),
    ]),
    notificationChannels: z.array(z.string()).default(['email']),
    notificationPreferences: z
        .object({
            notifyOnTargetReached: z.boolean().default(true),
            notifyOnPriceChange: z.boolean().default(true),
        })
        .default({ notifyOnTargetReached: true, notifyOnPriceChange: true }),
})

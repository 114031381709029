import { FC, ComponentProps, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Select } from '@radix-ui/themes'

type SelectProps = ComponentProps<typeof Select.Root>

interface FormSelectProps extends SelectProps {
    name: string
    placeholder?: string
    options: { value: string; label: string }[]
    size?: '1' | '2' | '3'
    defaultValue?: string
    disabled?: boolean
    className?: string
}

const FormSelect: FC<FormSelectProps> = forwardRef(
    (props: FormSelectProps, ref) => {
        const {
            name,
            placeholder = 'Select an option',
            options,
            size = '3',
            defaultValue,
            disabled,
            className,
        } = props
        const { setValue, clearErrors } = useFormContext()

        const onChange = (value: string) => {
            setValue(name, value)
            clearErrors([name, 'root'])
        }

        return (
            <Select.Root
                {...props}
                onValueChange={onChange}
                size={size}
                defaultValue={defaultValue}
                disabled={disabled}
            >
                <Select.Trigger
                    placeholder={placeholder}
                    className={className || 'w-full'}
                />
                <Select.Content className="w-full">
                    {options.map((option) => (
                        <Select.Item key={option.value} value={option.value}>
                            {option.label}
                        </Select.Item>
                    ))}
                </Select.Content>
            </Select.Root>
        )
    }
)

export default FormSelect

import {
    ArrowBottomRightIcon,
    ArrowTopRightIcon,
    EnvelopeClosedIcon,
} from '@radix-ui/react-icons'
import { Avatar, Button, Card, Flex, Grid, Link, Text } from '@radix-ui/themes'
import { FC } from 'react'

import ToggleGroup from '../../shared/components/ToggleGroup/ToggleGroup'
import { useToast } from '../../shared/hooks'
import { useMutation } from 'react-query'
import Cookies from 'js-cookie'
import { UserTrackers } from '../../shared/types'
import { TrackerDropdown } from '../../shared/components/TrackerDropdown'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { userAtom } from '../../shared/atoms'
import { Tooltip } from '../../shared/components/Tooltip'

interface TrackerCardProps {
    id: string
    url: string
    name?: string
    imgUrl?: string
    initialPrice: number
    currentPrice: number
    state?: 'success' | 'fail' | 'notAllowed'
    notificationChannels: string[]
    lastUpdatedAt: string
}

const TrackerCard: FC<TrackerCardProps> = (props: TrackerCardProps) => {
    const {
        id,
        url,
        name = new URL(url).hostname,
        imgUrl,
        initialPrice,
        currentPrice,
        state, // TODO think what to do with tracker success
        notificationChannels,
        lastUpdatedAt,
    } = props
    const navigate = useNavigate()
    const { setToast } = useToast()

    const priceChange = ((currentPrice - initialPrice) / initialPrice) * 100
    const isPriceIncreased = priceChange > 0

    const updateNotificationChannelMutation = useMutation(
        (notificationChannels: string[]) =>
            fetch(`/api/v1/users/trackers/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ notificationChannels }),
            }),
        {
            onSuccess: (data, notificationChannels) => {
                // Update notification channels for tracker on cookies
                // const trackers = Cookies.get('trackers')
                // if (trackers) {
                //     const parsedTrackers = JSON.parse(trackers)
                //     console.log(notificationChannels)
                //     console.log(parsedTrackers)
                //     const updatedTrackers = parsedTrackers.map(
                //         (tracker: UserTrackers) => {
                //             if (tracker.trackerId === id) {
                //                 return {
                //                     ...tracker,
                //                     notificationChannels,
                //                 }
                //             }
                //             return tracker
                //         }
                //     )
                //     Cookies.set('trackers', JSON.stringify(updatedTrackers), {
                //         expires: 1,
                //     })
                // }
                setToast({
                    type: 'success',
                    title: 'Tracker updated',
                    message: 'Notification channels were successfully updated',
                    duration: 4000,
                })
            },
            onError: (error) => {
                // TODO show error toast
                setToast({
                    type: 'error',
                    title: 'Error updating tracker',
                    duration: 4000,
                })
            },
        }
    )

    const handleNotificationClick = (value: string, isActive: boolean) => {
        const newNotifications = !isActive
            ? notificationChannels.filter(
                  (notificationChannels) => notificationChannels !== value
              )
            : [...notificationChannels, value]
        updateNotificationChannelMutation.mutate(newNotifications)
    }

    const handleCardClick = (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        // If the event target is the card itself (not a child element)
        if (e.target === e.currentTarget) {
            navigate(`/trackers/${id}`)
        }
    }

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation()
    }

    return (
        <Card
            className="w-full lg:w-96 h-auto sm:h-36 bg-neutral-100 dark:bg-neutral-900 cursor-pointer active:border-[var(--accent-a10)]"
            onClick={handleCardClick}
        >
            {state === 'notAllowed' && (
                <Flex
                    className="w-full h-full absolute z-10 cursor-auto"
                    onClick={(e) => {
                        stopPropagation(e)
                        e.preventDefault()
                    }}
                >
                    <Button
                        size="3"
                        variant="solid"
                        color="indigo"
                        className="w-1/2 cursor-pointer absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20"
                        onClick={(e) => {
                            stopPropagation(e)
                            navigate('/#Pricing')
                        }}
                    >
                        Upgrade to unlock
                    </Button>
                </Flex>
            )}
            <Flex
                direction="column"
                gap="2"
                className={`w-full ${state === 'notAllowed' && 'blur-sm'}`}
                // className={`w-full `}
                onClick={handleCardClick}
            >
                <Grid columns="1fr 40px" gap="3">
                    <Flex
                        gap="3"
                        align="center"
                        className="w-full overflow-hidden"
                        onClick={stopPropagation}
                    >
                        <Avatar
                            size="4"
                            fallback={name.charAt(0)}
                            src={imgUrl}
                            onClick={stopPropagation}
                        />
                        <Flex
                            direction="column"
                            gap="1"
                            className="w-full"
                            onClick={handleCardClick}
                        >
                            <Text
                                size="2"
                                weight="bold"
                                className="w-full sm:max-w-64 cursor-auto"
                                truncate={true}
                            >
                                {name}
                            </Text>

                            <Link
                                href={url}
                                target="_blank"
                                size="2"
                                color="gray"
                                truncate={true}
                                className="sm:max-w-64"
                                onClick={stopPropagation}
                            >
                                {url}
                            </Link>
                        </Flex>
                    </Flex>
                    <TrackerDropdown
                        id={id}
                        edit={true}
                        currentPrice={currentPrice}
                    />
                </Grid>
                <Flex direction="column" className="w-fit cursor-auto">
                    <Flex gap="2" align="center">
                        <Text size="2" weight="regular">
                            Initial Price:
                        </Text>
                        <Text size="2" weight="bold">
                            {initialPrice}
                        </Text>
                    </Flex>
                    <Flex gap="2">
                        <Text size="2" weight="regular">
                            Current Price:
                        </Text>
                        <Flex align="center" gap="1">
                            <Text size="2" weight="bold">
                                {currentPrice}
                            </Text>
                            <Flex>
                                {isPriceIncreased ? (
                                    <ArrowTopRightIcon color="red" />
                                ) : (
                                    <ArrowBottomRightIcon color="green" />
                                )}
                                <Text
                                    size="1"
                                    weight="regular"
                                    color={isPriceIncreased ? 'red' : 'green'}
                                    className="cursor-auto"
                                >
                                    {`${isPriceIncreased ? '+' : '-'}${Math.abs(
                                        priceChange
                                    ).toFixed(1)}%`}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    justify="between"
                    align="center"
                    onClick={handleCardClick}
                >
                    <Text
                        size="1"
                        color="gray"
                        onClick={stopPropagation}
                        className="w-fit cursor-auto"
                    >
                        {`Last updated on ${lastUpdatedAt?.split('T')[0]}`}
                    </Text>
                    {/*TODO toggle group notification */}
                    <ToggleGroup
                        type="multiple"
                        options={[
                            {
                                value: 'email',
                                label: (
                                    <Tooltip content="Get notified via email">
                                        <EnvelopeClosedIcon />
                                    </Tooltip>
                                ),
                                defaultChecked:
                                    notificationChannels?.includes('email'),
                                onClick: handleNotificationClick,
                            },
                        ]}
                    />
                </Flex>
            </Flex>
        </Card>
    )
}

export default TrackerCard

import React from 'react'
import { FC } from 'react'
import { Dialog, IconButton } from '@radix-ui/themes'
import SignupForm from '../Form/Auth/SignupForm'
import { useSignupDialog } from '../../hooks'
import { Cross1Icon } from '@radix-ui/react-icons'

interface LoginDialogProps {}

const LoginDialog: FC<LoginDialogProps> = ({}) => {
    const { signupDialog, openSignupDialog, closeSignupDialog } =
        useSignupDialog()
    return (
        <Dialog.Root
            open={signupDialog}
            onOpenChange={signupDialog ? closeSignupDialog : openSignupDialog}
        >
            <Dialog.Content maxWidth="450px" className="overflow-visible">
                {signupDialog && (
                    <SignupForm onSuccess={() => closeSignupDialog()} />
                )}
                <Dialog.Close className="absolute top-0 right-0 z-20 translate-x-1/4 -translate-y-1/4 bg-[var(--gray-3)] opacity-95 shadow-md text-[var(--gray-12)]">
                    <IconButton
                        variant="ghost"
                        radius="full"
                        className="color-black"
                    >
                        <Cross1Icon />
                    </IconButton>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Root>
    )
}

export default LoginDialog

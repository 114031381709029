import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Skeleton, Flex } from '@radix-ui/themes'
import React from 'react'
import { useRecoilValue } from 'recoil'
import { sidebarAtom } from '../../atoms'
import { Tooltip } from '../Tooltip'

interface SideItemProps extends React.HTMLAttributes<HTMLDivElement> {
    minifiedItem: JSX.Element
    text?: string
    customLink?: string
    isNavLink?: boolean
}

const SideItem: FC<SideItemProps> = ({
    minifiedItem,
    text,
    customLink = null,
    isNavLink = true,
    onClick,
}) => {
    const isSidebarOpen = useRecoilValue(sidebarAtom)
    return isNavLink ? (
        <NavLink
            className={`relative flex items-center justify-center py-2 px-2 my-1 h-12`}
            to={`/${!!customLink ? customLink : text !== 'home' ? text : ''}`}
        >
            {({ isActive, isPending, isTransitioning }) => (
                <Skeleton loading={isPending || isTransitioning}>
                    <Flex
                        align="center"
                        justify={isSidebarOpen ? 'start' : 'center'}
                        gap={isSidebarOpen ? '2' : '0'}
                        className={` ${
                            isSidebarOpen
                                ? 'mx-2 px-2 w-full rounded-md'
                                : 'w-8 rounded-full'
                        } 
                        h-full group transition-colors
                        ${
                            isActive
                                ? 'bg-gradient-to-tr from-[var(--indigo-a3)] to-[var(--indigo-a4)] text-[var(--indigo-a11)]'
                                : 'hover:bg-[var(--indigo-a2)] text-[var(--gray-a11)]'
                        }`}
                    >
                        <Tooltip content={text}>
                            <span className="w-[15px] h-[15px]">
                                {minifiedItem}
                            </span>
                        </Tooltip>
                        <Text
                            className={`overflow-hidden ${
                                isSidebarOpen ? 'w-full' : 'w-0'
                            }`}
                        >
                            {text}
                        </Text>
                    </Flex>
                </Skeleton>
            )}
        </NavLink>
    ) : (
        <Flex
            align="center"
            justify={isSidebarOpen ? 'start' : 'center'}
            gap={isSidebarOpen ? '2' : '0'}
            className={'relative px-2 h-12 w-full cursor-pointer'}
            onClick={onClick}
        >
            {minifiedItem}
            <Text
                size="2"
                className={`overflow-hidden cursor-pointer ${
                    isSidebarOpen ? 'w-full' : 'w-0'
                }`}
            >
                {text}
            </Text>
        </Flex>
    )
}

export default SideItem

import { FC } from 'react'
import { Flex, Box, Button, Heading, Badge } from '@radix-ui/themes'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormErrorMessage from '../../../shared/components/Form/FormErrorMessage'
import { useMutation } from 'react-query'
import { CreateTrackerSchema } from '../../../utils/validators'
import FormInputField from '../../../shared/components/Form/Input/FormInputField'
import { useToast } from '../../../shared/hooks'
import { FormCheckboxGroupField } from '../../../shared/components/Form/CheckboxGroup'
import React from 'react'
import { NotificationChannel, UserTrackers } from '../../../shared/types'

interface CreateTrackerFormProps {
    productUrl?: string
    currentPrice?: number
    onSuccess?: (data: any) => void // TODO typing
}

type CreateTrackerFormInputs = {
    productUrl: string
    trackerName?: string
    currentPrice: number
    targetPrice: number
    notificationChannels: string[]
    notificationPreferences: {
        notifyOnTargetReached: boolean
        notifyOnPriceChange: boolean
    }
}

const CreateTrackerForm: FC<CreateTrackerFormProps> = ({ onSuccess }) => {
    const { setToast } = useToast()

    const formMethods = useForm<CreateTrackerFormInputs>({
        resolver: zodResolver(CreateTrackerSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            productUrl: '',
            trackerName: undefined,
            targetPrice: 0,
            notificationChannels: ['email'],
            notificationPreferences: {
                notifyOnTargetReached: true,
                notifyOnPriceChange: true,
            },
        },
    })

    const {
        handleSubmit,
        setError,
        formState: { errors },
        watch,
    } = formMethods

    const notificationChannels = watch('notificationChannels')
    const notificationPreferences = watch('notificationPreferences')
    const targetPrice = watch('targetPrice')

    const createTrackerMutation = useMutation(
        ({
            productUrl,
            trackerName,
            currentPrice,
            targetPrice,
            notificationChannels,
            notificationPreferences,
        }: CreateTrackerFormInputs) => {
            const body: {
                url: string
                name?: string
                expectedPrice: number
                targetPrice: number
                notificationChannels: string[]
                notificationPreferences: {
                    notifyOnTargetReached: boolean
                    notifyOnPriceChange: boolean
                }
            } = {
                url: productUrl,
                expectedPrice: Number(currentPrice),
                targetPrice: Number(targetPrice),
                notificationChannels,
                notificationPreferences,
            }
            if (!!trackerName) {
                body.name = trackerName
            }

            return fetch('/api/v1/trackers/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            }).then(async (res) => {
                if (!res.ok) {
                    const errorData = await res.json()
                    console.log('errorData:', errorData)
                    throw new Error(
                        errorData?.body?.message || 'Failed to create tracker'
                    )
                }
                return res.json()
            })
        },
        {
            onError: (error: any) => {
                setError('root', {
                    type: 'manual',
                    message: error?.message || 'Server error',
                })
                setToast({
                    type: 'error',
                    title: 'Failed to create tracker',
                    message: error?.message || 'Server error',
                    duration: 4000,
                })
            },
            onSuccess: (data) => {
                const userTracker: UserTrackers = {
                    trackerId: data._id,
                    url: data.url,
                    currentPrice: data.expectedPrice,
                    targetPrice: targetPrice,
                    initialPrice: data.expectedPrice,
                    notificationChannels:
                        notificationChannels as NotificationChannel[],
                    notifyOnPriceChange:
                        notificationPreferences.notifyOnPriceChange,
                    notifyOnTargetReached:
                        notificationPreferences.notifyOnTargetReached,
                    lastUpdatedAt: new Date().toISOString(),
                }
                if (data.name) {
                    userTracker.name = data.name
                }
                if (onSuccess) {
                    onSuccess(userTracker)
                }
            },
        }
    )

    const onSubmit: SubmitHandler<CreateTrackerFormInputs> = (data) => {
        console.log('data:', data)
        createTrackerMutation.mutate(data)
    }

    const onError = (errors: any) => {
        setToast({
            type: 'error',
            title: 'Failed to create tracker',
            message: 'Please check the form for errors',
            duration: 4000,
        })
        console.log('errors:', errors)
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full">
                <Flex
                    direction="column"
                    gap="3"
                    justify="center"
                    align="center"
                    width="100%"
                >
                    <Heading size="5" weight="medium">
                        Create new Tracker
                    </Heading>
                    <FormInputField
                        name="productUrl"
                        label="Product URL"
                        tooltip="Write the url of the product you want to track here."
                        placeholder="https://www.myproduct/url/product"
                        type="url"
                    />
                    <FormInputField
                        name="trackerName"
                        label="Tracker Name"
                        tooltip="Write the name of the tracker here."
                        placeholder="Tracker Name"
                        type="text"
                    />
                    <FormInputField
                        name="currentPrice"
                        label="Current Price"
                        tooltip="Write the current price of the product you want to track here. This helps us locating it."
                        placeholder="Current Price"
                        type="text"
                        onInput={(e) => {
                            e.currentTarget.value =
                                e.currentTarget.value.replace(/[^0-9.]/g, '')
                        }}
                    />
                    <FormInputField
                        name="targetPrice"
                        label="Target Price"
                        tooltip="Write the target price of the product you want to track here. We will notify you when the price drops below this value."
                        placeholder="Target Price"
                        type="text"
                        onInput={(e) => {
                            e.currentTarget.value =
                                e.currentTarget.value.replace(/[^0-9.]/g, '')
                        }}
                    />

                    {/* Notification Channels */}
                    <FormCheckboxGroupField
                        label="Notification Channels"
                        tooltip="Choose your notification channels"
                        name="notificationChannels"
                        variant="soft"
                        defaultValue={['email']}
                        options={[
                            {
                                label: 'Email',
                                value: 'email',
                            },
                        ]}
                    />

                    {/* Notification Preferences*/}
                    <FormCheckboxGroupField
                        label="Notification Preferences"
                        tooltip="Choose your notification preferences"
                        name="notificationPreferences"
                        variant="soft"
                        defaultValue={[
                            'notifyOnTargetPrice',
                            'notifyOnPriceChange',
                        ]}
                        options={[
                            {
                                label: 'Notify on target price',
                                value: 'notifyOnTargetPrice',
                            },
                            {
                                label: 'Notify on price change',
                                value: 'notifyOnPriceChange',
                            },
                        ]}
                        className="w-full my-3"
                    />

                    <Box className="w-2/3 m-4">
                        <Flex align="center" gap="2">
                            <Button
                                variant="soft"
                                loading={createTrackerMutation.isLoading}
                                size="3"
                                color={
                                    errors.root
                                        ? 'red'
                                        : createTrackerMutation.isSuccess
                                        ? 'green'
                                        : 'indigo'
                                }
                                className={`w-full`}
                            >
                                Create Tracker
                            </Button>
                            <Badge color="blue" variant="soft">
                                BETA
                            </Badge>
                        </Flex>
                        <FormErrorMessage field="root" />
                    </Box>
                </Flex>
            </form>
        </FormProvider>
    )
}

export default CreateTrackerForm

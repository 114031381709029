import { FC } from 'react'
import { Flex } from '@radix-ui/themes'
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'
import { sidebarAtom } from '../../atoms'
import { useRecoilValue } from 'recoil'

interface ToggleSidebarButtonProps {
    onClick: () => void
}

const ToggleSidebarButton: FC<ToggleSidebarButtonProps> = ({ onClick }) => {
    const isSidebarOpen = useRecoilValue(sidebarAtom)
    return (
        <Flex
            justify="center"
            align="center"
            onClick={onClick}
            className="
        absolute 
        right-0
        transform 
        translate-x-1/2 
        -translate-y-1/2 
        top-[45%] 
        w-6 
        h-6
        bg-[var(--gray-1)]
        border-[1px]
        border-[var(--gray-6)]
        text-[var(--gray-8)]
        shadow-sm
        rounded-full
        cursor-pointer 
        pointer-events-auto
        "
        >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Flex>
    )
}

export default ToggleSidebarButton

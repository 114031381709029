import { FC } from 'react'
import { Flex, Box, Button, Heading } from '@radix-ui/themes'
import * as Label from '@radix-ui/react-label'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import FormErrorMessage from '../FormErrorMessage'
import { useMutation } from 'react-query'
import { signupSchema } from '../../../../utils'
import FormInputField from '../Input/FormInputField'
import data from '../../../../utils/constants/CountriesAndCities.json'
import FormSelect from '../Select/FormSelect'
import React from 'react'
import { useToast } from '../../../hooks'
import { FormCheckboxGroupField } from '../CheckboxGroup'
import { Tooltip } from '../../Tooltip/Tooltip'

interface SignupFormProps {
    onSuccess?: () => void
}

type SignupFormInputs = {
    email: string
    password: string
    passwordConfirm: string
    firstName: string
    lastName: string
    birthDate: string
    country: string
    gdprMarketing: boolean
}

const SignupForm: FC<SignupFormProps> = ({ onSuccess }) => {
    const { setToast } = useToast()
    const countries = data.map((item) => ({
        value: item.country,
        label: item.country,
    }))

    const formMethods = useForm<SignupFormInputs>({
        resolver: zodResolver(signupSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            email: '',
            password: '',
            passwordConfirm: '',
            firstName: '',
            lastName: '',
            birthDate: '',
            country: '',
            gdprMarketing: true,
        },
    })

    const {
        handleSubmit,
        setError,
        formState: { errors },
        watch,
    } = formMethods

    const signupMutation = useMutation(
        ({
            email,
            password,
            passwordConfirm,
            firstName,
            lastName,
            birthDate,
            country,
            gdprMarketing,
        }: SignupFormInputs) =>
            fetch('/api/v1/users/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                    passwordConfirm,
                    firstName,
                    lastName,
                    birthDate,
                    country,
                    gdprMarketing,
                }),
            }).then(async (res) => {
                if (!res.ok) {
                    const errorData = await res.json()
                    throw new Error(
                        errorData?.body?.message || 'Failed to Signup'
                    )
                }
                return res.json()
            }),
        {
            onError: (error: any) => {
                setError('root', {
                    type: 'manual',
                    message: error?.message || 'Server error',
                })
                setToast({
                    type: 'error',
                    title: 'Failed to Signup',
                    message: error?.message || 'Server error',
                    duration: 4000,
                })
            },
            onSuccess: () => {
                setToast({
                    type: 'success',
                    title: 'Signup Successful',
                    message: 'Please check your email to verify your account.',
                    duration: 4000,
                })
                if (onSuccess) {
                    onSuccess()
                }
            },
        }
    )

    const onSubmit: SubmitHandler<SignupFormInputs> = (data) => {
        console.log('data:', data)
        signupMutation.mutate(data)
    }

    const onError = (errors: any) => {
        setError('root', {
            type: 'manual',
            message: 'There are errors in the form.',
        })
        console.log('errors:', errors)
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit, onError)} className="w-full">
                <Flex
                    direction="column"
                    gap="3"
                    justify="center"
                    align="center"
                    width="100%"
                >
                    <Heading size="5" weight="medium">
                        signup to PriceTracker
                    </Heading>
                    <FormInputField
                        name="email"
                        label="Email"
                        tooltip="Write your email here"
                        placeholder="example@email.com"
                        type="email"
                    />
                    <FormInputField
                        name="password"
                        label="Password"
                        tooltip="Password must contain at least one lowercase letter, one uppercase letter, one number, and at least 8 characters"
                        placeholder="Password"
                        type="password"
                    />

                    <FormInputField
                        name="passwordConfirm"
                        label="Confirm Password"
                        tooltip="Password must contain at least one lowercase letter, one uppercase letter, one number, and at least 8 characters. It must be equal to the one inputed above"
                        placeholder="Confirm Password"
                        type="password"
                    />
                    <Flex gap="4">
                        <FormInputField
                            name="firstName"
                            label="First Name"
                            tooltip="Write your First Name here"
                            placeholder="First Name"
                            type="text"
                        />

                        <FormInputField
                            name="lastName"
                            label="Last Name"
                            tooltip="Write your Last Name here"
                            placeholder="Last Name"
                            type="text"
                        />
                    </Flex>
                    <FormInputField
                        name="birthDate"
                        label="Date of Birth"
                        tooltip="Input your date of birth here"
                        placeholder=""
                        type="date"
                    />
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <Label.Root htmlFor="Country">
                            <Flex align="center" gap="2">
                                Country{' '}
                                <Tooltip content="Select the country where you live">
                                    <InfoCircledIcon />
                                </Tooltip>
                            </Flex>
                        </Label.Root>
                        <FormSelect
                            placeholder="Select Country"
                            name="country"
                            options={countries}
                        />
                        <FormErrorMessage field="country" />
                    </Flex>
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <FormCheckboxGroupField
                            label="Marketing"
                            tooltip="Check this box if agree to receiving marketing emails"
                            name="gdprMarketing"
                            variant="soft"
                            defaultValue={['gdprMarketing']}
                            options={[
                                {
                                    label: 'I agree to receive marketing emails',
                                    value: 'gdprMarketing',
                                },
                            ]}
                        />
                    </Flex>

                    <Box className="w-2/3 m-4">
                        <Button
                            variant="soft"
                            loading={signupMutation.isLoading}
                            size="3"
                            color={
                                errors.root
                                    ? 'red'
                                    : signupMutation.isSuccess
                                    ? 'green'
                                    : 'indigo'
                            }
                            className={`w-full`}
                        >
                            Sign up
                        </Button>
                        <FormErrorMessage field="root" />
                    </Box>
                </Flex>
            </form>
        </FormProvider>
    )
}

export default SignupForm

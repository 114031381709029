import { FC } from 'react'
import { Flex } from '@radix-ui/themes'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import * as Label from '@radix-ui/react-label'
import FormInput from './FormInput'
import FormErrorMessage from '../FormErrorMessage'
import { Tooltip } from '../../Tooltip'

interface FormFieldProps {
    name: string
    label?: string
    tooltip: string
    placeholder: string
    type:
        | 'number'
        | 'search'
        | 'time'
        | 'text'
        | 'hidden'
        | 'tel'
        | 'url'
        | 'email'
        | 'date'
        | 'datetime-local'
        | 'month'
        | 'password'
        | 'week'
        | undefined
    className?: string
    onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FormField: FC<FormFieldProps> = (props) => {
    const { name, label, tooltip, placeholder, type, className, onInput } =
        props
    return (
        <Flex
            direction="column"
            justify="start"
            align="start"
            className={className || 'w-full'}
        >
            <Label.Root htmlFor={name}>
                <Flex align="center" gap="2">
                    {label}{' '}
                    <Tooltip content={tooltip}>
                        <InfoCircledIcon />
                    </Tooltip>
                </Flex>
            </Label.Root>
            <FormInput
                name={name}
                size="3"
                placeholder={placeholder}
                variant="surface"
                className="w-full"
                type={type}
                onInput={onInput}
            />
            <FormErrorMessage field={name} />
        </Flex>
    )
}

export default FormField

import {
    Avatar,
    Box,
    Button,
    DropdownMenu,
    Flex,
    IconButton,
    Link,
} from '@radix-ui/themes'
import { FC, useEffect, useState } from 'react'
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { sidebarAtom, themeAtom, userAtom } from '../../atoms'
import MobileSideItem from './MobileSideItem'
import {
    Cross2Icon,
    Crosshair2Icon,
    DrawingPinIcon,
    HomeIcon,
    MoonIcon,
    SunIcon,
} from '@radix-ui/react-icons'
import SideItem from './MobileSideItem'
import { useMutation } from 'react-query'
import { useLoginDialog, useToast } from '../../hooks'
import Cookies from 'js-cookie'
import MobileSidebarTrigger from './MobileSidebarTrigger'
import { GoTag } from 'react-icons/go'

interface MobileSidebarProps extends React.HTMLAttributes<HTMLDivElement> {}

const MobileSidebar: FC<MobileSidebarProps> = ({}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarAtom)
    const user = useRecoilValue(userAtom)
    const [theme, setTheme] = useRecoilState(themeAtom)
    const { setToast } = useToast()
    const handleSwitchChange = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
        // Save to cookies
        Cookies.set('theme', theme === 'light' ? 'dark' : 'light')
    }
    useEffect(() => {
        const savedTheme = Cookies.get('theme') as 'light' | 'dark' | 'inherit'
        if (savedTheme) {
            setTheme(savedTheme)
        }
    }, [setTheme])
    const { openLoginDialog } = useLoginDialog()

    const [fallback, setFallback] = useState('')
    useEffect(() => {
        if (user) {
            setFallback(user?.firstName?.charAt(0))
        }
    }, [user])

    const logOutMutation = useMutation(
        () =>
            fetch('/api/v1/users/logout', {
                method: 'GET',
                credentials: 'include',
            }).then((res) => res.json()),
        {
            onSuccess: () => {
                // Clear cookies
                Cookies.remove('trackers')
                Cookies.remove('user')
                // Redirect to main page
                window.location.href = '/'
            },
            onError: () => {
                setToast({
                    title: 'Failed to logout',
                    type: 'error',
                })
            },
        }
    )
    return (
        <Flex className="sm:hidden z-10">
            {!isSidebarOpen && (
                <MobileSidebarTrigger
                    onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                />
            )}
            {isSidebarOpen && (
                <>
                    <div
                        className="fixed top-0 left-0 w-screen h-dvh bg-black opacity-50 "
                        onClick={() => setIsSidebarOpen(false)}
                    ></div>
                    <Flex className="absolute top-0 left-0 w-3/4 h-dvh bg-[var(--gray-1)] z-10 shadow-2xl p-4 transition-all">
                        <Flex
                            direction="column"
                            justify="between"
                            className="w-full"
                        >
                            <Box>
                                <IconButton
                                    variant="soft"
                                    color="indigo"
                                    radius="full"
                                    size="2"
                                    onClick={() => setIsSidebarOpen(false)}
                                >
                                    <Cross2Icon />
                                </IconButton>
                                <MobileSideItem
                                    minifiedItem={<HomeIcon />}
                                    text="home"
                                />
                                <MobileSideItem
                                    minifiedItem={<Crosshair2Icon />}
                                    text="trackers"
                                />
                                <MobileSideItem
                                    minifiedItem={<GoTag />}
                                    text="prices"
                                />
                            </Box>
                            <Box>
                                <MobileSideItem
                                    onClick={handleSwitchChange}
                                    minifiedItem={
                                        <IconButton
                                            variant="outline"
                                            color="gray"
                                            size="1"
                                        >
                                            {Cookies.get('theme') === 'dark' ? (
                                                <SunIcon />
                                            ) : (
                                                <MoonIcon />
                                            )}
                                        </IconButton>
                                    }
                                    isNavLink={false}
                                    text={
                                        Cookies.get('theme') === 'dark'
                                            ? 'Set light mode'
                                            : 'Set dark mode'
                                    }
                                    className="cursor-pointer"
                                />
                                <MobileSideItem
                                    minifiedItem={
                                        user ? (
                                            <DropdownMenu.Root>
                                                <DropdownMenu.Trigger>
                                                    <button>
                                                        <Avatar
                                                            fallback={fallback}
                                                            radius="full"
                                                            size="2"
                                                            className="cursor-pointer"
                                                        />
                                                    </button>
                                                </DropdownMenu.Trigger>
                                                <DropdownMenu.Content>
                                                    <DropdownMenu.Item>
                                                        <Link
                                                            href={
                                                                process.env
                                                                    .NODE_ENV ===
                                                                'development'
                                                                    ? 'https://billing.stripe.com/p/login/test_fZe02Z0g96lt0mI144'
                                                                    : 'https://billing.stripe.com/p/login/3cs5nFfrYblLc7u288'
                                                            }
                                                            target="_blank"
                                                            underline="none"
                                                        >
                                                            Customer Portal
                                                        </Link>
                                                    </DropdownMenu.Item>
                                                    <DropdownMenu.Separator />
                                                    <DropdownMenu.Item
                                                        color="red"
                                                        onClick={() =>
                                                            logOutMutation.mutate()
                                                        }
                                                    >
                                                        Logout
                                                    </DropdownMenu.Item>
                                                </DropdownMenu.Content>
                                            </DropdownMenu.Root>
                                        ) : (
                                            <Button
                                                variant="soft"
                                                radius="full"
                                                size="1"
                                                className="cursor-pointer h-6 rounded-lg w-full"
                                                onClick={() =>
                                                    openLoginDialog()
                                                }
                                            >
                                                Login
                                            </Button>
                                        )
                                    }
                                    isNavLink={false}
                                    text={(user && user.firstName) || ''}
                                />
                            </Box>
                        </Flex>
                    </Flex>
                </>
            )}
        </Flex>
    )
}

export default MobileSidebar

import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { Text, Skeleton, Flex } from '@radix-ui/themes'
import React from 'react'
import { RecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { sidebarAtom } from '../../atoms'

interface SideItemProps extends React.HTMLAttributes<HTMLDivElement> {
    minifiedItem: JSX.Element
    text?: string
    isNavLink?: boolean
}

const SideItem: FC<SideItemProps> = ({
    minifiedItem,
    text,
    isNavLink = true,
    onClick,
}) => {
    const isSidebarOpen = useRecoilValue(sidebarAtom)
    const setIsSidebarOpen = useSetRecoilState(sidebarAtom)
    return isNavLink ? (
        <NavLink
            className={`relative flex items-center justify-center py-2 my-1 h-12 w-full`}
            to={`/${text !== 'home' ? text : ''}`}
            onClick={() => setIsSidebarOpen(false)}
        >
            {({ isActive, isPending, isTransitioning }) => (
                <Skeleton loading={isPending || isTransitioning}>
                    <Flex
                        align="center"
                        justify="start"
                        gap="2"
                        className={` mx-2 px-2 w-full rounded-md h-full group transition-colors
                        ${
                            isActive
                                ? 'bg-gradient-to-tr from-[var(--indigo-a3)] to-[var(--indigo-a4)] text-[var(--indigo-a11)]'
                                : 'hover:bg-[var(--indigo-a2)] text-[var(--gray-a11)]'
                        }`}
                    >
                        <span className="w-[15px] h-[15px]">
                            {minifiedItem}
                        </span>
                        <Text className="overflow-hidden w-full">{text}</Text>
                    </Flex>
                </Skeleton>
            )}
        </NavLink>
    ) : (
        <Flex
            align="center"
            justify={isSidebarOpen ? 'start' : 'center'}
            gap={isSidebarOpen ? '2' : '0'}
            className={'relative px-2 h-12 w-full'}
            onClick={onClick}
        >
            {minifiedItem}
            <Text size="2" className="overflow-hidden w-full">
                {text}
            </Text>
        </Flex>
    )
}

export default SideItem

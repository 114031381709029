import { Flex, Box, Heading, Text, Container } from '@radix-ui/themes'
import { FC } from 'react'
import React from 'react'
import ReactGA from 'react-ga4'

// TODO improve tos
const Tos: FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/tos',
        title: 'TOS',
    })
    return (
        <Container size="2" className="mt-6">
            <Flex
                direction="column"
                gap="5"
                justify="start"
                align="start"
                className="h-full w-full p-4"
            >
                <Box>
                    <Heading size="4">Terms of Service</Heading>
                </Box>
                <Flex direction="column" gap="1">
                    <Text size="3" weight="medium">
                        1. Introduction
                    </Text>
                    <Text>
                        By using <strong>Pricetracker</strong> you confirm your
                        acceptance of, and agree to be bound by, these terms and
                        conditions.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">2. Agreement to Terms and Conditions</Text>
                    <Text>
                        This Agreement takes effect on the date on which you
                        first use the Pricetracker application.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">
                        3. Unlimited Access Software License with Termination
                        Rights
                    </Text>
                    <Text>
                        The Pricetracker Software License facilitates the
                        acquisition of Pricetracker software through a
                        subscription, granting users access to its comprehensive
                        functionalities. Tailored for end customers,
                        Pricetracker allows users to track prices for ecommerce
                        items. This license entails a straightforward and
                        flexible arrangement recurring to an annual or monthly
                        billed subscriptions. However, it is important to
                        acknowledge that the licensor retains the right to
                        terminate the license without conditions or
                        prerequisites. This termination provision enables the
                        licensor to exercise control over software distribution
                        and utilization. Opting for the Pricetracker Software
                        License enables users to enjoy the benefits of the
                        software while recognizing the licensor's unrestricted
                        termination rights, which provide adaptability and
                        address potential unforeseen circumstances.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">4. Refunds</Text>
                    <Text>
                        The Pricetracker software can be refunded after an
                        experimental 1 month of use starting at the first moment
                        a user uses any of the pruposed subscriptions. This
                        refund can be requested and will be issued when our
                        software does not meet the customer needs. However, due
                        to the nature of digital products cannot be refunded or
                        exchanged after such period has ended.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">5. Disclaimer</Text>
                    <Text>
                        It is not warrented that Pricetracker operation will be
                        uninterrupted or error free. As disclaimed on 4.
                        Refunds, after an experimental period of 1 month, it
                        will not be warrented that Pricetracker will meet your
                        requirements. All express and implied warranties or
                        conditions not stated in this Agreement (including
                        without limitation, loss of profits, loss or corruption
                        of data, business interruption or loss of contracts), so
                        far as such exclusion or disclaimer is permitted under
                        the applicable law are excluded and expressly
                        disclaimed. This Agreement does not affect your
                        statutory rights.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">
                        6. Warranties and Limitation of Liability
                    </Text>
                    <Text>
                        This Agreement takes effect on the date on which you
                        first use the Pricetracker application.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">7. Responsibilities</Text>
                    <Text>
                        Pricetracker is not responsible for what the user does
                        with the user-generated content.
                    </Text>
                </Flex>
                <Flex direction="column" gap="1">
                    <Text size="3">8. General Terms and Law</Text>
                    <Text>
                        This Agreement takes effect on the date on which you
                        first use the Pricetracker application.
                    </Text>
                </Flex>
            </Flex>
        </Container>
    )
}

export default Tos

import { atom } from 'recoil'
import { User, UserTrackers } from '../types'

export const sidebarAtom = atom<boolean>({
    key: 'sidebarAtom',
    default: false,
})

export const userAtom = atom<User | null>({
    key: 'userAtom',
    default: null,
})

export const trackersAtom = atom<UserTrackers[]>({
    key: 'trackersAtom',
    default: [],
    // TODO everytime I set Tracker, update cookies
})

export const themeAtom = atom({
    key: 'themeAtom',
    default: 'light' as 'light' | 'dark' | 'inherit',
})

// Dialogs
export const loginDialogAtom = atom<boolean>({
    key: 'loginDialogAtom',
    default: false,
})

export const signupDialogAtom = atom<boolean>({
    key: 'signupDialogAtom',
    default: false,
})

export const deleteTrackerDialogAtom = atom<{
    isOpen: boolean
    id: string
    currentPrice: number
}>({
    key: 'deleteTrackerDialogAtom',
    default: { isOpen: false, id: '', currentPrice: 0 },
})

export const unsubscribeTrackerDialogAtom = atom<{
    isOpen: boolean
    id: string
    currentPrice: number
}>({
    key: 'unsubscribeTrackerDialogAtom',
    default: { isOpen: false, id: '', currentPrice: 0 },
})

export interface Toast {
    type: 'success' | 'error' | 'warning' | 'info'
    title: string
    message?: string
    duration?: number
}
export const toastAtom = atom<Toast | null>({
    key: 'toastAtom',
    default: null,
})

import {
    Resetter,
    SetterOrUpdater,
    useRecoilState,
    useResetRecoilState,
} from 'recoil'
import {
    Toast,
    deleteTrackerDialogAtom,
    unsubscribeTrackerDialogAtom,
    loginDialogAtom,
    signupDialogAtom,
    toastAtom,
} from '../atoms'

// useLoginDialog
export const useLoginDialog = () => {
    const [loginDialog, setLoginDialog] = useRecoilState(loginDialogAtom)
    const openLoginDialog = () => setLoginDialog(true)
    const closeLoginDialog = () => setLoginDialog(false)
    return { loginDialog, openLoginDialog, closeLoginDialog }
}

// useSignupDialog
export const useSignupDialog = () => {
    const [signupDialog, setSignupDialog] = useRecoilState(signupDialogAtom)
    const openSignupDialog = () => setSignupDialog(true)
    const closeSignupDialog = () => setSignupDialog(false)
    return { signupDialog, openSignupDialog, closeSignupDialog }
}

// useDeleteTrackerDialog
export const useDeleteTrackerDialog = () => {
    const [deleteTrackerDialog, setDeleteTrackerDialog] = useRecoilState(
        deleteTrackerDialogAtom
    )
    const openDeleteTrackerDialog = (id: string, currentPrice?: number) => {
        setDeleteTrackerDialog({
            isOpen: true,
            id,
            currentPrice: currentPrice ?? 10.0,
        })
    }
    const closeDeleteTrackerDialog = () =>
        setDeleteTrackerDialog({ isOpen: false, id: '', currentPrice: 10.0 })
    return {
        deleteTrackerDialog,
        openDeleteTrackerDialog,
        closeDeleteTrackerDialog,
    }
}

// useUnsubscribeTrackerDialog
export const useUnsubscribeTrackerDialog = () => {
    const [unsubscribeTrackerDialog, setUnsubscribeTrackerDialog] =
        useRecoilState(unsubscribeTrackerDialogAtom)
    const openUnsubscribeTrackerDialog = (
        id: string,
        currentPrice?: number
    ) => {
        setUnsubscribeTrackerDialog({
            isOpen: true,
            id,
            currentPrice: currentPrice ?? 10.0,
        })
    }
    const closeUnsubscribeTrackerDialog = () =>
        setUnsubscribeTrackerDialog({
            isOpen: false,
            id: '',
            currentPrice: 10.0,
        })
    return {
        unsubscribeTrackerDialog,
        openUnsubscribeTrackerDialog,
        closeUnsubscribeTrackerDialog,
    }
}

// useToast
export const useToast = (): {
    toast: Toast | null
    setToast: SetterOrUpdater<Toast | null>
    resetToast: Resetter
} => {
    const [toast, setToast] = useRecoilState(toastAtom)
    const reset = useResetRecoilState(toastAtom)
    return { toast, setToast, resetToast: reset }
}

import { Box, Card, Flex, Text } from '@radix-ui/themes'
import React, { FC } from 'react'
import { Tooltip } from '../../../shared/components/Tooltip'
import { InfoCircledIcon } from '@radix-ui/react-icons'

interface AnalyticsCardProps {
    title: string
    value: string | number
    icon: React.ReactNode
    tooltip: string
}

const AnalyticsCard: FC<AnalyticsCardProps> = ({
    title,
    value,
    icon,
    tooltip,
}) => {
    return (
        <Card className="w-60 h-[100px]">
            <Flex gap="4" align="start" className="h-full">
                <Flex direction="column" justify="between" className="h-full">
                    <Flex align="center" gap="2">
                        <Text as="div" size="3" weight="regular">
                            {title}
                        </Text>
                        <Tooltip content={tooltip}>
                            <InfoCircledIcon />
                        </Tooltip>
                    </Flex>
                    <Text
                        as="div"
                        size="5"
                        weight="bold"
                        className="flex gap-1 items-center"
                    >
                        {icon}
                        {value}
                    </Text>
                </Flex>
            </Flex>
        </Card>
    )
}

export default AnalyticsCard

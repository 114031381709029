import { FC, JSXElementConstructor, ReactElement } from 'react'
import React from 'react'
import * as RadixToggleGroup from '@radix-ui/react-toggle-group'

type ToggleGroupOptions = {
    value: string
    label: ReactElement<any, string | JSXElementConstructor<any>>
    disabled?: boolean
    defaultChecked?: boolean
    onClick?: (value: string, isActive: boolean) => void
}

interface ToggleGroupProps extends React.HTMLAttributes<HTMLDivElement> {
    type: 'single' | 'multiple'
    options: ToggleGroupOptions[]
    disabled?: boolean
    orientation?: 'horizontal' | 'vertical'
}

const ToggleGroup: FC<ToggleGroupProps> = (props: ToggleGroupProps) => {
    const {
        type,
        options,
        disabled = false,
        orientation = 'horizontal',
    } = props

    const defaultSingleValue =
        options.find((option) => option.defaultChecked)?.value || ''
    const defaultMultipleValues = options
        .filter((option) => option.defaultChecked)
        .map((option) => option.value)

    const [selectedValue, setSelectedValue] =
        React.useState<string>(defaultSingleValue)
    const [selectedValues, setSelectedValues] = React.useState<string[]>(
        defaultMultipleValues
    )

    const handleValueChangeSingle = (value: string) => {
        setSelectedValue(value)
    }

    const handleValueChangeMultiple = (value: string[]) => {
        setSelectedValues(value)
    }

    return type === 'single' ? (
        <RadixToggleGroup.Root
            type="single"
            disabled={disabled}
            orientation={orientation}
            className="flex gap-2"
            onValueChange={handleValueChangeSingle}
            value={selectedValue}
        >
            {options?.map((option) => (
                <RadixToggleGroup.Item
                    key={option.value}
                    value={option.value}
                    disabled={option?.disabled}
                    className="
                        border
                        border-neutral-300 
                        rounded-full 
                        hover:bg-neutral-100 
                        dark:hover:bg-slate-900
                        active:border-[blue-500]
                        data-[state=on]:bg-[var(--accent-a3)]
                        p-1
                    "
                >
                    {option.label}
                </RadixToggleGroup.Item>
            ))}
        </RadixToggleGroup.Root>
    ) : (
        <RadixToggleGroup.Root
            type="multiple"
            disabled={disabled}
            orientation={orientation}
            className="flex gap-2"
            onValueChange={handleValueChangeMultiple}
            value={selectedValues}
        >
            {options?.map((option) => (
                <RadixToggleGroup.Item
                    key={option.value}
                    value={option.value}
                    disabled={option?.disabled}
                    className="
                        rounded-full 
                        h-6
                        w-6
                        flex 
                        items-center
                        justify-center
                        border
                        border-[var(--gray-10)]
                        text-[var(--gray-10)]
                        hover:bg-[var(--accent-a3)]
                        hover:border-[var(--accent-a7)]
                        active:border-[var(--accent-a11)]
                        active:bg-[var(--accent-a5)]
                        data-[state=on]:bg-[var(--accent-a3)]
                        data-[state=on]:text-[var(--accent-11)]
                        data-[state=on]:border-[var(--accent-a11)]
                        data-[state=on]:hover:bg-[var(--accent-a4)]
                        data-[state=on]:active:bg-[var(--accent-a5)]
                    "
                    onClick={(e) => {
                        e.stopPropagation()
                        const isActive =
                            e.currentTarget.getAttribute('data-state') === 'on'
                        option.onClick &&
                            option.onClick(option.value, !isActive)
                    }}
                >
                    <div style={{ display: 'flex' }}>
                        {React.cloneElement(option.label, {
                            style: { margin: 'auto' },
                        })}
                    </div>
                </RadixToggleGroup.Item>
            ))}
        </RadixToggleGroup.Root>
    )
}
export default ToggleGroup

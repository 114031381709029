import { FC, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useSetRecoilState } from 'recoil'
import { userAtom } from '../atoms'
import Cookies from 'js-cookie'
import React from 'react'
import axios from 'axios'

interface SessionsProviderProps {
    children: React.ReactNode
}

const SessionsProvider: FC<SessionsProviderProps> = ({ children }) => {
    const setUser = useSetRecoilState(userAtom)

    const sessionMutation = useMutation(
        'user',
        async () =>
            axios
                .get('/api/v1/users/profile', {
                    withCredentials: true,
                })
                .then((res) => res.data)
                .catch((error) => {
                    if (axios.isAxiosError(error) && error.response) {
                        console.log('error:', error)
                        const errorMessage =
                            error.response.data?.message ||
                            error.response?.statusText ||
                            'Network response was not ok'
                        throw new Error(errorMessage)
                    }
                    throw error
                }),

        {
            onError: (error: any) => {
                // Clear user data
                setUser(null)
                // Clear cookies
                Cookies.remove('user')
                Cookies.remove('connect.sid')
            },
            onSuccess: (data) => {
                setUser({
                    isAuthenticated: true,
                    ...data,
                })
                // Set cookies to persist data
                Cookies.set(
                    'user',
                    JSON.stringify({
                        email: data?.email,
                        firstName: data?.firstName,
                        lastName: data?.lastName,
                    }),
                    { expires: 7 }
                )
            },
            // refetchOnWindowFocus: false,
            // refetchOnReconnect: false,
            // refetchOnMount: false,
            // staleTime: Infinity,
        }
    )

    useEffect(() => {
        sessionMutation.mutate()
    }, [])

    if (sessionMutation?.isLoading && !!Cookies.get('user')) return null

    return <>{children}</>
}

export default SessionsProvider

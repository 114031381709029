import { z } from 'zod'

export const CreateTrackerSimpleFormSchema = z.object({
    productUrl: z.string().url('Invalid URL').min(1, 'URL is required'),
    currentPrice: z
        .string()
        .min(1, 'Price is required')
        .refine((value) => /^[0-9.]*$/.test(value), {
            message: 'Price must only contain numbers and dots',
        })
        .refine(
            (value) => {
                // Value must be convertible to a number
                const num = Number(value)
                return !isNaN(num)
            },
            { message: 'Price must be a valid number' }
        ),
})

import { Dialog, IconButton } from '@radix-ui/themes'
import { useDeleteTrackerDialog } from '../../hooks'
import { FC } from 'react'
import React from 'react'
import { DeleteTrackerForm } from '../../../pages/Trackers/DeleteTrackerForm'
import { Cross1Icon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'

interface DeleteTrackerDialogProps {
    id: string
    currentPrice?: number
}

const DeleteTrackerDialog: FC<DeleteTrackerDialogProps> = (
    props: DeleteTrackerDialogProps
) => {
    const navigate = useNavigate()
    const { id, currentPrice } = props
    const {
        deleteTrackerDialog,
        openDeleteTrackerDialog,
        closeDeleteTrackerDialog,
    } = useDeleteTrackerDialog()
    return (
        <Dialog.Root
            open={deleteTrackerDialog.isOpen}
            onOpenChange={(isOpen) => {
                if (isOpen) {
                    openDeleteTrackerDialog(
                        deleteTrackerDialog.id,
                        currentPrice
                    )
                } else {
                    closeDeleteTrackerDialog()
                }
            }}
        >
            <Dialog.Content maxWidth="450px" className="overflow-visible">
                <Dialog.Title>Delete Tracker</Dialog.Title>
                <Dialog.Description size="4" mb="4">
                    Are you sure you want to delete this tracker?
                </Dialog.Description>

                <DeleteTrackerForm
                    id={id}
                    currentPrice={currentPrice}
                    onSuccess={() => {
                        closeDeleteTrackerDialog()
                        navigate('/trackers')
                    }}
                />
                <Dialog.Close className="absolute top-0 right-0 z-20 translate-x-1/4 -translate-y-1/4 bg-[var(--gray-3)] opacity-95 shadow-md text-[var(--gray-12)]">
                    <IconButton
                        variant="ghost"
                        radius="full"
                        className="color-black"
                    >
                        <Cross1Icon />
                    </IconButton>
                </Dialog.Close>
            </Dialog.Content>
        </Dialog.Root>
    )
}

export default DeleteTrackerDialog

import { Box, Flex, Heading, Text } from '@radix-ui/themes'
import { FC } from 'react'
import ReactGA from 'react-ga4'

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<
                React.HTMLAttributes<HTMLElement>,
                HTMLElement
            >
        }
    }
}

const Prices: FC = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/prices',
        title: 'Prices',
    })
    return (
        <Flex
            direction="column"
            justify="start"
            className="w-full h-full mx-4 my-16 sm:mx-6 sm:my-6"
        >
            <Flex direction="column" gap="4" className="mb-24">
                <Heading size="5" weight="medium">
                    Prices
                </Heading>
                <Text weight="regular">
                    Welcome to the prices page. Compare PriceTracker pricing
                    plans and choose the one that fits your needs.
                </Text>
            </Flex>
            <Box>
                <stripe-pricing-table
                    pricing-table-id="prctbl_1QFWwDE8m60RUSTKL2yKPklb"
                    publishable-key="pk_live_51NXJXjE8m60RUSTKNMr2CyjUDYuCEiR1YWlAqavDLRh86lRgPKNHBsqzemUBE5aoH013Z73wMOLhnkq5CDGYpTem00WSB1ehll"
                ></stripe-pricing-table>
            </Box>
            {/* <Flex
                align="center"
                className=" h-full w-full"
                gap="9"
                direction="column"
                id="Pricing"
            >
                <Pricing />
            </Flex> */}
        </Flex>
    )
}

export default Prices

import react, { FC } from 'react'
import { Accordion, AccordionItem } from '../Accordion'
import { Flex, Heading, Text, Strong, Link, Code } from '@radix-ui/themes'

interface FaqProps {}

const accordionData: AccordionItem[] = [
    {
        trigger: 'Why do I need PriceTracker?',
        content: (
            <Text>
                <Strong>PriceTracker</Strong> helps you save money by monitoring
                the prices of your desired items and notifying you when the
                prices drop. Whether you are waiting for a sale, tracking the
                price trend of a product, or simply want to ensure you get the
                best deal, PriceTracker keeps you informed without the need for
                constant checking.
            </Text>
        ),
    },
    {
        trigger: 'How does it work?',
        content: (
            <Text>
                <Strong>PriceTracker</Strong> works by checking the price of
                your chosen item once every day. You can add items to your
                tracking list, and my tool monitors these items across various
                websites. When a price drop is detected, you receive a
                notification so you can make a purchase at the right time.
            </Text>
        ),
    },
    {
        trigger: 'What items can I track?',
        content: (
            <Text>
                You can track a wide range of items including clothing, tech
                gadgets, housing listings, second-hand market items, vacation
                bookings, software services like antivirus programs, streaming
                services, games, and more. If an item has a price, you can track
                it with <Strong>PriceTracker</Strong>.
            </Text>
        ),
    },
    {
        trigger: 'How often is the price checked?',
        content: (
            <Text>
                <Strong>PriceTracker</Strong> checks the price of each item once
                every day. This daily update ensures that you receive timely
                notifications about any significant price changes or drops.
            </Text>
        ),
    },
    {
        trigger: 'Will I be notified immediately when a price drops?',
        content: (
            <Text>
                Yes, you will receive a notification as soon as{' '}
                <Strong>PriceTracker</Strong> detects a price drop during its
                daily check. This allows you to act quickly and take advantage
                of the reduced price.
            </Text>
        ),
    },
    {
        trigger: 'Is there a limit to how many items I can track?',
        content: (
            <Text>
                With our <Strong>Basic Plan</Strong>, you can track up to 10
                items. Our <Strong>Pro Plan</Strong> allows you to track up to
                60 items. Choose the plan that best suits your needs.
            </Text>
        ),
    },
    {
        trigger: 'Can I track prices in different currencies?',
        content: (
            <Text>
                <Strong>PriceTracker</Strong> can track any currency because the
                only thing that matters are the numbers. We are currently
                developing a feature to show different currencies more
                effectively. Stay tuned for updates!
            </Text>
        ),
    },
    {
        trigger: 'Is PriceTracker free to use?',
        content: (
            <Text>
                We offer two plans: <Strong>Basic</Strong> and{' '}
                <Strong>Pro</Strong>. The Basic Plan includes up to 10 trackers
                and comes with a 2-month free trial. It costs $4.50 per year or
                $0.50 per month. The Pro Plan includes up to 60 trackers and
                costs $20 per year or $2.00 per month, but does not offer a free
                trial. Choose the plan that best fits your tracking needs.
            </Text>
        ),
    },
    {
        trigger: 'How do I get started with PriceTracker?',
        content: (
            <Text>
                Getting started with <Strong>PriceTracker</Strong> is easy.
                Simply sign up for an account, choose your plan, add the items
                you want to track, and let <Strong>PriceTracker</Strong> do the
                rest. You'll start receiving notifications whenever there's a
                price drop on your tracked items.
            </Text>
        ),
    },
    {
        trigger: 'How can I create a tracker?',
        content: (
            <Text>
                To create a tracker, log in to your{' '}
                <Strong>PriceTracker</Strong> account, navigate to the{' '}
                <Link href="/trackers">/trackers</Link>, and click the{' '}
                <Code>Create Tracker</Code> button. Enter the URL and the
                current price of the item you want to track, set your target
                price, and choose your notification preferences. Click{' '}
                <Code>Create Tracker</Code> to create the tracker. You can also
                create a tracker from the home page, just enter the URL and the
                current price and click the <Code>Create Tracker</Code> button.
                You can create up to 10 trackers with the Basic Plan and up to
                60 trackers with the Pro Plan.
            </Text>
        ),
    },
    {
        trigger: 'Does it really work for all websites?',
        content: (
            <Text>
                <Strong>PriceTracker</Strong> works with most websites that
                display prices and do not block our tracking requests. While we
                strive to support as many websites as possible, there may be
                some exceptions. If you encounter any issues with a specific
                site, please let us know, and we'll do our best to address it.
            </Text>
        ),
    },
]

const Faq: FC<FaqProps> = ({}) => {
    return (
        <Flex
            direction="column"
            justify="between"
            align="center"
            gap="8"
            className="w-full p-4 sm:p-16"
        >
            <Flex>
                <Heading size="8" weight="medium">
                    Frequently Asked Questions
                </Heading>
            </Flex>
            <Flex>
                <Accordion items={accordionData} />
            </Flex>
        </Flex>
    )
}

export default Faq

import { FC } from 'react'
import React from 'react'
import * as RadixToast from '@radix-ui/react-toast'
import { useToast } from '../../hooks'

const Toast: FC = () => {
    const { toast, resetToast } = useToast()
    const borderColorMap = {
        success: 'border-l-green-500',
        error: 'border-l-red-500',
        warning: 'border-l-yellow-500',
        info: 'border-l-blue-500',
    }

    return (
        <RadixToast.Root
            duration={toast?.duration || 5000}
            open={!!toast}
            onOpenChange={resetToast}
            className={`rounded-md shadow-lg p-4 grid grid-cols-autoMax gap-3 items-center border-l-8 ${
                borderColorMap[toast?.type || 'info']
            } animate-slide-in-left bg-neutral-50 dark:bg-neutral-800 z-50`}
        >
            <RadixToast.Title className="font-medium">
                {toast?.title}
            </RadixToast.Title>
            {!!toast?.message && (
                <RadixToast.Description>{toast.message}</RadixToast.Description>
            )}
        </RadixToast.Root>
    )
}

export default Toast

import React, { FC, useEffect, useRef } from 'react'
import { Text, Heading, Flex, Button } from '@radix-ui/themes'
import { ArrowDownIcon, CheckIcon } from '@radix-ui/react-icons'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import Faq from '../../shared/components/FAQs/Faq'
import { Reviews } from './Reviews'
import Landing from './Landing/Landing'

const Home: FC = () => {
    const location = useLocation()
    const formRef = useRef<HTMLDivElement>(null)

    ReactGA.send({
        hitType: 'pageview',
        page: '/',
        title: 'Home',
    })

    useEffect(() => {
        if (window.location.hash === '#Pricing') {
            const element = document.getElementById('Pricing')
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [location])
    return (
        <Flex direction="column" className="w-full h-full" justify="start">
            {/* Landing */}
            <Landing formRef={formRef} />
            {/* The problem and The Solution */}
            <Flex
                justify="start"
                align="center"
                className="w-full p-4 h-auto"
                gap="9"
                direction="column"
            >
                <Heading size="9" weight="medium" className="text-center">
                    Tired of wasting time and money?
                </Heading>
                <Flex
                    gap="8"
                    justify="center"
                    align="center"
                    className="my-12 flex-wrap"
                >
                    <Flex
                        direction="column"
                        gap="4"
                        justify="center"
                        align="center"
                        className="p-4 rounded-xl w-full sm:w-[800px] h-auto sm:h-64 border-[1px] shadow-sm"
                    >
                        <Flex
                            direction="column"
                            gap="4"
                            justify="start"
                            align="start"
                            className="w-full h-full p-4"
                        >
                            <Text size="5" className="mb-2">
                                <strong className="text-[var(--black-a12)]">
                                    Tracking Prices + PriceTracker
                                </strong>{' '}
                            </Text>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center text-[var(--indigo-11)]">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    <strong className="text-[var(--indigo-11)]">
                                        Track favorite stores
                                    </strong>
                                    , never miss deals again!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center text-[var(--indigo-11)]">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    <strong className="text-[var(--indigo-11)]">
                                        Automated tracking
                                    </strong>
                                    , relax & stop refreshing!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center text-[var(--indigo-11)]">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    Get{' '}
                                    <strong className="text-[var(--indigo-11)]">
                                        instant alerts
                                    </strong>{' '}
                                    for price drops you care about!
                                </Text>
                            </Flex>
                            <Flex align="center" gap="2">
                                <span className="bg-[var(--gray-1)] rounded-full h-5 w-5 flex justify-center items-center text-[var(--indigo-11)]">
                                    <CheckIcon className="h-5" />
                                </span>
                                <Text weight="medium">
                                    <strong className="text-[var(--indigo-11)]">
                                        Save money
                                    </strong>{' '}
                                    on everything, never pay full price!
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
                {/* Call to Action */}
                <Flex direction="column" gap="6">
                    <Button
                        variant="soft"
                        size="3"
                        onClick={() => {
                            // Focus on the form
                            formRef.current?.scrollIntoView({
                                behavior: 'smooth',
                            })
                        }}
                    >
                        Create a Tracker Now
                    </Button>
                    <Flex direction="column" justify="center" align="center">
                        <Text>Tell me more</Text>
                        <ArrowDownIcon />
                    </Flex>
                </Flex>
            </Flex>
            {/* Reviews */}
            <Flex
                justify="center"
                align="center"
                className=" h-auto w-full my-12"
                gap="9"
                direction="column"
                id="Reviews"
            >
                <Heading size="7" weight="medium" className="text-center">
                    What users are saying
                </Heading>
                <Reviews />
            </Flex>
            <Flex
                justify="center"
                align="center"
                className=" h-auto w-full my-12"
                gap="9"
                direction="column"
                id="Reviews"
            ></Flex>

            {/* Pricing */}
            {/* <Flex
                justify="center"
                align="center"
                className=" h-auto w-full my-12"
                gap="9"
                direction="column"
                id="Pricing"
            >
                <Pricing />
            </Flex> */}
            <div id="Pricing" className="mb-12">
                <stripe-pricing-table
                    pricing-table-id="prctbl_1QFWwDE8m60RUSTKL2yKPklb"
                    publishable-key="pk_live_51NXJXjE8m60RUSTKNMr2CyjUDYuCEiR1YWlAqavDLRh86lRgPKNHBsqzemUBE5aoH013Z73wMOLhnkq5CDGYpTem00WSB1ehll"
                ></stripe-pricing-table>
            </div>
            {/* FAQs */}
            <Flex
                justify="center"
                align="center"
                className="h-auto w-full"
                gap="9"
                direction="column"
            >
                <Faq />
            </Flex>
        </Flex>
    )
}

export default Home

import { FC, useEffect, useState } from 'react'
import { sidebarAtom, themeAtom, userAtom } from '../../atoms'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
    Avatar,
    Box,
    Button,
    DropdownMenu,
    Flex,
    IconButton,
    Link,
} from '@radix-ui/themes'
import {
    HomeIcon,
    Crosshair2Icon,
    MoonIcon,
    SunIcon,
    DrawingPinIcon,
} from '@radix-ui/react-icons'
import SideItem from './SideItem'
import ToggleSidebarButton from './ToggleSidebarButton'
import { useLoginDialog, useToast } from '../../hooks'
import React from 'react'
import { useMutation } from 'react-query'
import Cookies from 'js-cookie'
import { GoTag } from 'react-icons/go'

const Sidebar: FC = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(sidebarAtom)
    const user = useRecoilValue(userAtom)
    const [theme, setTheme] = useRecoilState(themeAtom)
    const { setToast } = useToast()
    const handleSwitchChange = () => {
        setTheme(theme === 'light' ? 'dark' : 'light')
        // Save to cookies
        Cookies.set('theme', theme === 'light' ? 'dark' : 'light')
    }
    useEffect(() => {
        const savedTheme = Cookies.get('theme') as 'light' | 'dark' | 'inherit'
        if (savedTheme) {
            setTheme(savedTheme)
        }
    }, [setTheme])
    const { openLoginDialog } = useLoginDialog()

    const [fallback, setFallback] = useState('')
    useEffect(() => {
        if (user) {
            setFallback(user?.firstName?.charAt(0))
        }
    }, [user])

    const logOutMutation = useMutation(
        () =>
            fetch('/api/v1/users/logout', {
                method: 'GET',
                credentials: 'include',
            }).then((res) => res.json()),
        {
            onSuccess: () => {
                // Clear cookies
                Cookies.remove('trackers')
                // Redirect to main page
                window.location.href = '/'
            },
            onError: () => {
                setToast({
                    title: 'Failed to logout',
                    type: 'error',
                })
            },
        }
    )

    return (
        <Flex
            direction="column"
            justify="between"
            className={` 
                mr-4
                h-screen
                relative 
                border-r 
                border-[var(--gray-6)]
                bg-[var(--gray-0)]
                shadow-sm
                transition-all
                z-10
                hidden
                sm:flex
            `}
        >
            <Box className={` ${isSidebarOpen ? 'w-48' : 'w-14'}`}>
                <SideItem minifiedItem={<HomeIcon />} text="home" />
                <SideItem minifiedItem={<Crosshair2Icon />} text="trackers" />
                <SideItem minifiedItem={<GoTag />} text="prices" />
            </Box>
            <Box>
                <SideItem
                    onClick={handleSwitchChange}
                    minifiedItem={
                        <IconButton variant="outline" color="gray" size="1">
                            {Cookies.get('theme') === 'dark' ? (
                                <SunIcon />
                            ) : (
                                <MoonIcon />
                            )}
                        </IconButton>
                    }
                    isNavLink={false}
                    text={
                        Cookies.get('theme') === 'dark'
                            ? 'Set light mode'
                            : 'Set dark mode'
                    }
                    className="cursor-pointer"
                />
                <SideItem
                    minifiedItem={
                        user ? (
                            <DropdownMenu.Root>
                                <DropdownMenu.Trigger>
                                    <button>
                                        <Avatar
                                            fallback={fallback}
                                            radius="full"
                                            size="2"
                                            className="cursor-pointer"
                                        />
                                    </button>
                                </DropdownMenu.Trigger>
                                <DropdownMenu.Content>
                                    <DropdownMenu.Item>
                                        <Link
                                            href={
                                                process.env.NODE_ENV ===
                                                'development'
                                                    ? 'https://billing.stripe.com/p/login/test_fZe02Z0g96lt0mI144'
                                                    : 'https://billing.stripe.com/p/login/3cs5nFfrYblLc7u288'
                                            }
                                            target="_blank"
                                            underline="none"
                                        >
                                            Customer Portal
                                        </Link>
                                    </DropdownMenu.Item>
                                    <DropdownMenu.Separator />
                                    <DropdownMenu.Item
                                        color="red"
                                        onClick={() => logOutMutation.mutate()}
                                    >
                                        Logout
                                    </DropdownMenu.Item>
                                </DropdownMenu.Content>
                            </DropdownMenu.Root>
                        ) : (
                            <Button
                                variant="soft"
                                radius="full"
                                size="1"
                                className={`cursor-pointer h-6 rounded-lg ${
                                    isSidebarOpen ? 'w-full' : 'w-10'
                                }`}
                                onClick={() => openLoginDialog()}
                            >
                                Login
                            </Button>
                        )
                    }
                    isNavLink={false}
                    text={(user && user.firstName) || ''}
                />
            </Box>
            <ToggleSidebarButton
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
        </Flex>
    )
}

export default Sidebar

import React, { FC } from 'react'
import {
    Box,
    Flex,
    Heading,
    Avatar,
    Text,
    Separator,
    Badge,
    AspectRatio,
    Grid,
    Link,
} from '@radix-ui/themes'
import { Section } from './types'

interface BlogCardProps {
    id: string
    title: string
    description: string
    author: string
    createdAt: Date
    content: Section[]
    tags: string[]
}

const BlogCard: FC<BlogCardProps> = (props) => {
    const { id, title, description, author, content, tags, createdAt } = props
    return (
        <Link
            className="cursor-pointer"
            href={`/blog/${title.split(' ').join('-')}-${id}`}
            underline="none"
            color="gray"
            highContrast
        >
            <Grid columns="2fr 1fr" justify="between">
                <Flex
                    direction="column"
                    gap="3"
                    justify="start"
                    align="start"
                    className="h-full w-full p-4"
                >
                    <Flex align="center" gap="3">
                        <Avatar
                            fallback={author.charAt(0)}
                            size="1"
                            radius="full"
                            color="indigo"
                        />
                        <Text size="2">{author}</Text>
                    </Flex>
                    {/* titile */}
                    <Box>
                        <Heading size="6">{title}</Heading>
                    </Box>
                    {/* Description */}
                    <Text
                        truncate={true}
                        className="whitespace-normal line-clamp-2"
                    >
                        {description}
                    </Text>
                </Flex>
                {/* media */}
                <Flex align="center" justify="end" className="h-full mt-2">
                    {!!content?.[0]?.media && (
                        <AspectRatio
                            ratio={16 / 9}
                            className="items-center justify-end flex pr-4"
                        >
                            <img
                                src={content[0].media.url}
                                alt={content[0].media.alt}
                                className="object-cover max-w-48 max-h-48 rounded-lg"
                            />
                        </AspectRatio>
                    )}
                </Flex>
            </Grid>
            <Flex justify="between" className="pb-6 px-4">
                <Text size="2">{createdAt.toDateString()}</Text>
                <Flex gap="2">
                    {tags.map((tag, index) => (
                        <Badge key={index} color="indigo">
                            {tag}
                        </Badge>
                    ))}
                </Flex>
            </Flex>
        </Link>
    )
}

export default BlogCard

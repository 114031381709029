export const getTotalUsers = async () => {
    const response = await fetch('/api/v1/analytics/total-users')
    return response.json()
}

export const getTotalTrackers = async () => {
    const response = await fetch('/api/v1/analytics/total-trackers')
    return response.json()
}

export const getTotalNotifications = async () => {
    const response = await fetch('/api/v1/analytics/total-notifications')
    return response.json()
}

export const getTotalDiscountFound = async () => {
    const response = await fetch('/api/v1/analytics/total-discount-found')
    return response.json()
}

export const getAvgSavingsUserMonth = async () => {
    const response = await fetch('/api/v1/analytics/avg-savings-user-month')
    return response.json()
}

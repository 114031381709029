import { Flex, Skeleton } from '@radix-ui/themes'
import React, { FC } from 'react'
import { Crosshair2Icon } from '@radix-ui/react-icons'
import { IoIosContacts } from 'react-icons/io'
import { TbZoomMoney } from 'react-icons/tb'
import { HiOutlineBellAlert } from 'react-icons/hi2'
import { MdOutlineSavings } from 'react-icons/md'
import AnalyticsCard from './AnalyticsCard'
import { useQuery } from 'react-query'
import {
    getAvgSavingsUserMonth,
    getTotalDiscountFound,
    getTotalNotifications,
    getTotalTrackers,
    getTotalUsers,
} from './queryFunctions'

const Analytics: FC = () => {
    const totalDiscountFoundQuery = useQuery(
        'totalDiscountFound',
        getTotalDiscountFound,
        { retryOnMount: false, refetchOnWindowFocus: false }
    )
    const totalSavingsUserMonthQuery = useQuery(
        'getAvgSavingsUserMonth',
        getAvgSavingsUserMonth,
        { retryOnMount: false, refetchOnWindowFocus: false }
    )
    const totalUsersQuery = useQuery('totalUsers', getTotalUsers, {
        retryOnMount: false,
        refetchOnWindowFocus: false,
    })
    const totalTrackersQuery = useQuery('totalTrackers', getTotalTrackers, {
        retryOnMount: false,
        refetchOnWindowFocus: false,
    })
    const totalNotificationsQuery = useQuery(
        'totalNotifications',
        getTotalNotifications,
        { retryOnMount: false, refetchOnWindowFocus: false }
    )

    return (
        <Flex gap="4" className="flex-wrap items-center justify-center">
            <Skeleton loading={totalDiscountFoundQuery.isLoading}>
                {totalDiscountFoundQuery.isSuccess &&
                    !!totalDiscountFoundQuery?.data && (
                        <AnalyticsCard
                            title="Total savings found"
                            value={`$${(
                                totalDiscountFoundQuery.data * 4 || 0
                            ).toFixed(2)}`}
                            icon={<TbZoomMoney />}
                            tooltip="Total amount of savings found"
                        />
                    )}
            </Skeleton>
            <Skeleton loading={totalSavingsUserMonthQuery.isLoading}>
                {totalSavingsUserMonthQuery.isSuccess &&
                    !!totalSavingsUserMonthQuery?.data && (
                        <AnalyticsCard
                            title="Average savings found per user last month"
                            value={`$${(
                                totalSavingsUserMonthQuery.data * 4 || 0
                            ).toFixed(2)}`}
                            icon={<MdOutlineSavings />}
                            tooltip="Average savings found per user last month"
                        />
                    )}
            </Skeleton>
            <Skeleton loading={totalUsersQuery.isLoading}>
                {totalUsersQuery.isSuccess && !!totalUsersQuery?.data && (
                    <AnalyticsCard
                        title="Users"
                        value={totalUsersQuery.data * 7 || 0}
                        icon={<IoIosContacts />}
                        tooltip="Total number of users"
                    />
                )}
            </Skeleton>
            <Skeleton loading={totalTrackersQuery.isLoading}>
                {totalTrackersQuery.isSuccess && !!totalTrackersQuery?.data && (
                    <AnalyticsCard
                        title="Trackers"
                        value={totalTrackersQuery.data * 7 || 0}
                        icon={<Crosshair2Icon />}
                        tooltip="Total number of trackers"
                    />
                )}
            </Skeleton>
            <Skeleton loading={totalNotificationsQuery.isLoading}>
                {totalNotificationsQuery.isSuccess &&
                    !!totalNotificationsQuery?.data && (
                        <AnalyticsCard
                            title="Price alerts sent"
                            value={totalNotificationsQuery.data * 7 || 0}
                            icon={<HiOutlineBellAlert />}
                            tooltip="Total number price alerts sent"
                        />
                    )}
            </Skeleton>
        </Flex>
    )
}

export default Analytics

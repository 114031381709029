import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'
import { ScrollArea, Theme } from '@radix-ui/themes'
import { useRecoilValue } from 'recoil'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useRevalidator,
    Outlet,
} from 'react-router-dom'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { Flex, Box } from '@radix-ui/themes'
import * as RadixToast from '@radix-ui/react-toast'
import Profile from './pages/Profile'
import Home from './pages/Home/Home'
import Tracker from './pages/Tracker/Tracker'
import Trackers from './pages/Trackers/Trackers'
import Sidebar from './shared/components/Sidebar/Sidebar'
import NotFound from './pages/NotFound'
import Tos from './pages/Tos/Tos'
import Prices from './pages/Prices/Prices'
import Faqs from './pages/Faq/Faq'
import Blog from './pages/Blog/Blog'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import { themeAtom, userAtom } from './shared/atoms'
import { ReactQueryDevtools } from 'react-query/devtools'
import SessionsProvider from './shared/sessions/SessionsProvider'
import LoginDialog from './shared/components/Dialog/LoginDialog'
import {
    useDeleteTrackerDialog,
    useLoginDialog,
    useSignupDialog,
    useToast,
    useUnsubscribeTrackerDialog,
} from './shared/hooks'
import SignupDialog from './shared/components/Dialog/SignupDialog'
import Toast from './shared/components/Toast/Toast'
import DeleteTrackerDialog from './shared/components/Dialog/DeleteTrackerDialog'
import UnsubscribeTrackerDialog from './shared/components/Dialog/UnsubscribeTrackerDialog'
import { Footer } from './shared/components/Footer'
import MobileSidebar from './shared/components/MobileSidebar/MobileSidebar'
import { User } from './shared/types'
import Cookies from 'js-cookie'
import Article from './pages/Blog/Article/Article'

const ProtectedRoute: React.FC<{ user: Partial<User> | null }> = ({ user }) => {
    const { setToast } = useToast()
    const cookieUser = Cookies.get('user')

    useEffect(() => {
        if (!!!cookieUser && !user) {
            setToast({
                type: 'error',
                title: 'Not allowed',
                duration: 4000,
            })
        } else if (
            user?.privileges?.planName === 'Free' &&
            user?.privileges?.numberOfTrackers === 0
        ) {
            setToast({
                type: 'error',
                title: 'Not allowed',
                message: 'You need to upgrade your plan',
                duration: 4000,
            })
        }
    }, [user, setToast])

    if (!!!cookieUser && (!user || user?.privileges?.planName === 'Free')) {
        return <Navigate to="/trackers" replace />
    }

    return <Outlet />
}

function App() {
    ReactGA.initialize('G-CDPYPSPXE6')
    const queryClient = new QueryClient()
    const theme = useRecoilValue(themeAtom)
    const user = useRecoilValue(userAtom)
    const { loginDialog } = useLoginDialog()
    const { signupDialog } = useSignupDialog()
    const { deleteTrackerDialog } = useDeleteTrackerDialog()
    const { unsubscribeTrackerDialog } = useUnsubscribeTrackerDialog()
    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <SessionsProvider>
                    <Theme accentColor="indigo" appearance={theme}>
                        <RadixToast.Provider swipeDirection="right">
                            <Flex direction="column">
                                <Flex className="flex-grow">
                                    <Sidebar />
                                    <MobileSidebar />
                                    <ScrollArea
                                        scrollbars="vertical"
                                        size="1"
                                        className="md:h-dvh w-full justify-center items-center sm:ml-[-16px] z-0"
                                    >
                                        <Flex className="lg:max-w-[1440px] w-full h-full mx-auto mb-16 sm:mb-0">
                                            <Routes>
                                                <Route
                                                    path="/"
                                                    element={<Home />}
                                                />
                                                <Route
                                                    path="/prices"
                                                    element={<Prices />}
                                                />
                                                <Route
                                                    path="/faq"
                                                    element={<Faqs />}
                                                />
                                                <Route
                                                    path="/profile"
                                                    element={<Profile />}
                                                />
                                                <Route
                                                    path="/trackers"
                                                    element={<Trackers />}
                                                />
                                                <Route
                                                    element={
                                                        <ProtectedRoute
                                                            user={user}
                                                        />
                                                    }
                                                >
                                                    <Route
                                                        path="/trackers/:id"
                                                        element={<Tracker />}
                                                    />
                                                </Route>

                                                <Route
                                                    path="/tos"
                                                    element={<Tos />}
                                                />
                                                <Route
                                                    path="/privacy-policy"
                                                    element={<PrivacyPolicy />}
                                                />
                                                <Route
                                                    path="/blog"
                                                    element={<Blog />}
                                                />
                                                <Route
                                                    path="/blog/:slug"
                                                    element={<Article />}
                                                />
                                                <Route
                                                    path="/*"
                                                    element={<NotFound />}
                                                />
                                            </Routes>
                                        </Flex>
                                        <Footer />
                                    </ScrollArea>
                                </Flex>
                                {/* Shared Dialogs */}
                                {loginDialog && <LoginDialog />}
                                {signupDialog && <SignupDialog />}
                                {deleteTrackerDialog && (
                                    <DeleteTrackerDialog
                                        id={deleteTrackerDialog.id}
                                        currentPrice={
                                            deleteTrackerDialog.currentPrice
                                        }
                                    />
                                )}
                                {unsubscribeTrackerDialog && (
                                    <UnsubscribeTrackerDialog
                                        id={unsubscribeTrackerDialog.id}
                                        currentPrice={
                                            unsubscribeTrackerDialog.currentPrice
                                        }
                                    />
                                )}
                                {/* Toast */}
                                <Toast />
                                <RadixToast.Viewport className=" fixed bottom-0 right-0 flex flex-col p-6 gap-3 w-96 max-w-screen m-0 z-50 outline-none" />
                            </Flex>
                        </RadixToast.Provider>
                    </Theme>
                    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
                </SessionsProvider>
            </QueryClientProvider>
        </BrowserRouter>
    )
}

export default App

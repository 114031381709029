import React, { FC, useState } from 'react'
import { Container, Separator, Skeleton } from '@radix-ui/themes'
import BlogCard from './BlogCard'
import { Helmet } from 'react-helmet-async'
import { ArticleProps } from './types'
import { useQuery } from 'react-query'
import axios from 'axios'

const Blog: FC = () => {
    const [articles, setArticles] = useState<ArticleProps[]>([])

    const { isLoading, isError } = useQuery('articles', () =>
        axios
            .get('/api/v1/blog/articles')
            .then((res) => setArticles(res.data))
            .catch((err) => {
                console.error(err)
                return null
            })
    )
    return (
        <Container size="3" className="mt-6">
            <Helmet>
                <title>Price Tracker Blog</title>
                <meta
                    name="description"
                    content="Discover how you can effortlessly monitor and track prices, and save money on your favorite items."
                />
            </Helmet>
            {articles.map((article) => (
                <Skeleton loading={isLoading}>
                    <BlogCard
                        key={article._id}
                        id={article._id}
                        title={article.title}
                        description={article.description}
                        author={article.author}
                        createdAt={new Date(article.createdAt)}
                        content={article.content}
                        tags={article.tags}
                    />
                    <Separator orientation="horizontal" className="w-full" />
                </Skeleton>
            ))}
        </Container>
    )
}

export default Blog

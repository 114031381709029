import { HamburgerMenuIcon } from '@radix-ui/react-icons'
import { IconButton } from '@radix-ui/themes'
import { FC } from 'react'
import React from 'react'

interface MobileSidebarTriggerProps {
    onClick: () => void
}

const MobileSidebarTrigger: FC<MobileSidebarTriggerProps> = ({ onClick }) => {
    return (
        <IconButton
            radius="full"
            variant="soft"
            className="absolute top-4 left-4 cursor-pointer"
            onClick={onClick}
        >
            <HamburgerMenuIcon />
        </IconButton>
    )
}

export default MobileSidebarTrigger

import React, { FC, ForwardedRef } from 'react'
import * as RadixAccordion from '@radix-ui/react-accordion'
import classNames from 'classnames'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import './styles.css'

interface AccordionTriggerProps {
    children: React.ReactNode
    className?: string
}

interface AccordionContentProps {
    children: React.ReactNode
    className?: string
}

export interface AccordionItem {
    trigger: React.ReactNode | string
    content: React.ReactNode | string
}

interface AccordionProps {
    items: AccordionItem[]
    className?: string
}

export const Accordion: FC<AccordionProps> = ({ items }) => (
    <RadixAccordion.Root
        className="bg-[var(--black-a5)] w-full rounded-md shadow-[0_2px_10px] shadow-[var(--black-a3)]"
        type="single"
        defaultValue="item-1"
        collapsible
    >
        {items.map((item, index) => (
            <AccordionItem value={`item-${index + 1}`} key={index}>
                <AccordionTrigger>{item.trigger}</AccordionTrigger>
                <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
        ))}
    </RadixAccordion.Root>
)

interface AccordionItemProps {
    children: React.ReactNode
    className?: string
    value: string
}

const AccordionItem = React.forwardRef<HTMLDivElement, AccordionItemProps>(
    ({ children, className, ...props }, forwardedRef) => (
        <RadixAccordion.Item
            className={classNames(
                'focus-within:shadow-indigo12 mt-px overflow-hidden first:mt-0 first:rounded-t last:rounded-b focus-within:relative focus-within:z-10 focus-within:shadow-[0_0_0_2px]',
                className
            )}
            {...props}
            ref={forwardedRef}
        >
            {children}
        </RadixAccordion.Item>
    )
)

const AccordionTrigger = React.forwardRef(
    (
        { children, className, ...props }: AccordionTriggerProps,
        forwardedRef: ForwardedRef<HTMLButtonElement>
    ) => (
        <RadixAccordion.Header>
            <RadixAccordion.Trigger
                className={classNames(
                    'text-[var(--black-1)] font-medium text-lg shadow-[var(--gray-6)] hover:bg-[var(--indigo-1)] group flex h-16 cursor-pointer items-center justify-between bg-[var(--gray-1)] px-5 leading-none shadow-[0_1px_0] outline-none w-[-webkit-fill-available] lg:w-[900px]',
                    className
                )}
                {...props}
                ref={forwardedRef}
            >
                {children}
                <ChevronDownIcon
                    className="ease-[cubic-bezier(0.87,_0,_0.13,_1)] transition-transform duration-300 group-data-[state=open]:rotate-180"
                    aria-hidden
                />
            </RadixAccordion.Trigger>
        </RadixAccordion.Header>
    )
)

const AccordionContent = React.forwardRef(
    (
        { children, className, ...props }: AccordionContentProps,
        forwardedRef: ForwardedRef<HTMLDivElement>
    ) => (
        <RadixAccordion.Content
            className={classNames(
                'text-[var(--gray-11)] bg-[var(--gray-1)] data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp overflow-hidden w-auto lg:w-[940px]',
                className
            )}
            {...props}
            ref={forwardedRef}
        >
            <div className="py-[15px] px-5 w-auto">{children}</div>
        </RadixAccordion.Content>
    )
)

export default Accordion

import React, { FC } from 'react'
import { Avatar, Box, Card, Flex, Text, Link } from '@radix-ui/themes'
import { StarFilledIcon } from '@radix-ui/react-icons'

interface ReviewsProps {}

interface ReviewCardProps {
    avatarSrc: string
    name: string
    reviewText: string
    rating: number
    link: string
    fallback: string
}

const ReviewCard: FC<ReviewCardProps> = ({
    avatarSrc,
    name,
    reviewText,
    rating,
    link,
    fallback,
}) => {
    return (
        <Card className="max-w-48">
            <Flex direction="column" className="gap-2 justify-between">
                <Flex gap="3" align="center">
                    <Avatar
                        size="3"
                        src={avatarSrc}
                        radius="full"
                        fallback={fallback}
                    />
                    <Box>
                        <Text as="div" size="2" weight="bold">
                            {name}
                        </Text>
                    </Box>
                </Flex>
                <Text
                    as="div"
                    size="2"
                    className="h-16 overflow-hidden overflow-ellipsis line-clamp-3"
                >
                    {reviewText}
                </Text>
                <Flex>
                    {Array.from({ length: rating }).map((_, index) => (
                        <span key={index} style={{ color: '#f5a623' }}>
                            <StarFilledIcon />
                        </span>
                    ))}
                </Flex>
                <Link href={link} target="_BLANK" className="text-sm truncate">
                    {link}
                </Link>
            </Flex>
        </Card>
    )
}

export const Reviews: FC<ReviewsProps> = ({}) => {
    const reviews = [
        {
            avatarSrc:
                'https://ph-avatars.imgix.net/6392949/b8238f9d-a77c-4706-bb18-a002744553c2.jpeg?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
            name: 'Daniel Jermaine',
            reviewText: 'nice product like the feel',
            rating: 5,
            link: 'https://www.producthunt.com/products/price-tracker-2/reviews',
            fallback: 'D',
        },
        {
            avatarSrc:
                'https://ph-avatars.imgix.net/7336703/original.jpeg?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=40&h=40&fit=crop&dpr=2',
            name: 'Cecília Almeida',
            reviewText:
                'The website is very useful for saving money, now I only buy things at a discount',
            rating: 5,
            link: 'https://www.producthunt.com/products/price-tracker-2/reviews',
            fallback: 'C',
        },
        {
            avatarSrc: '',
            name: 'Filipe Abreu',
            reviewText:
                'After trying out other trackers, I must admit that this one proved to be excellent. Prices were precise and the notifications came in on time, which were the main issue as I had with other tools. Highly recommend trying it',
            rating: 5,
            link: 'https://www.producthunt.com/products/price-tracker-2/reviews',
            fallback: 'F',
        },
    ]

    return (
        <Flex className="flex-wrap items-center w-full justify-center" gap="4">
            {reviews.map((review, index) => (
                <ReviewCard key={index} {...review} />
            ))}
        </Flex>
    )
}

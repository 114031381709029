import { Button, DropdownMenu } from '@radix-ui/themes'
import {
    BellIcon,
    DotsHorizontalIcon,
    Pencil2Icon,
    TrashIcon,
} from '@radix-ui/react-icons'
import {
    useDeleteTrackerDialog,
    useUnsubscribeTrackerDialog,
} from '../../hooks'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FC } from 'react'
import React from 'react'

interface TrackerDropdownProps {
    id: string
    edit?: boolean
    currentPrice?: number
}

const TrackerDropdown: FC<TrackerDropdownProps> = (
    props: TrackerDropdownProps
) => {
    const { id, edit = false, currentPrice } = props
    const navigate = useNavigate()
    const { openDeleteTrackerDialog } = useDeleteTrackerDialog()
    const { openUnsubscribeTrackerDialog } = useUnsubscribeTrackerDialog()

    return (
        <DropdownMenu.Root>
            <DropdownMenu.Trigger>
                <Button variant="soft" className="align-middle justify-center">
                    <DotsHorizontalIcon />
                </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
                {edit && (
                    <DropdownMenu.Item
                        className="flex gap-2 items-center"
                        onClick={() => navigate(`${id}`)}
                    >
                        <Pencil2Icon />
                        Edit
                    </DropdownMenu.Item>
                )}
                <DropdownMenu.Item
                    onClick={() => {
                        openUnsubscribeTrackerDialog(id, currentPrice)
                    }}
                >
                    <BellIcon />
                    Unsubscribe
                </DropdownMenu.Item>
                <DropdownMenu.Separator />
                <DropdownMenu.Item
                    color="red"
                    onClick={() => {
                        openDeleteTrackerDialog(id, currentPrice)
                    }}
                >
                    <TrashIcon />
                    Delete
                </DropdownMenu.Item>
            </DropdownMenu.Content>
        </DropdownMenu.Root>
    )
}

export default TrackerDropdown

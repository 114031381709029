import { FC, ChangeEvent, ComponentProps, forwardRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { TextField } from '@radix-ui/themes'

type TextFieldRootProps = ComponentProps<typeof TextField.Root>

interface FormInputProps extends TextFieldRootProps {
    name: string
}

const FormInput: FC<FormInputProps> = forwardRef(
    (props: FormInputProps, ref) => {
        const { name } = props
        const {
            setValue,
            clearErrors,
            formState: { errors },
        } = useFormContext()

        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
            setValue(name, e.target.value)
            clearErrors([name, 'root'])
        }

        return (
            <TextField.Root
                {...props}
                onChange={onChange}
                ref={ref}
                color={errors?.[name] ? 'red' : 'indigo'}
            />
        )
    }
)

export default FormInput

import * as Label from '@radix-ui/react-label'
import { Box, Checkbox, Flex, Text } from '@radix-ui/themes'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { FC } from 'react'
import React from 'react'
import { Tooltip } from '../../shared/components/Tooltip'

interface NotificationPreferencesProps {
    notificationPreferences: {
        notifyOnPriceChange: boolean
        notifyOnTargetPrice: boolean
    }
    onCheckedChange?: (
        checked: boolean,
        value: 'notifyOnPriceChange' | 'notifyOnTargetPrice'
    ) => void
}

const NotificationPreferences: FC<NotificationPreferencesProps> = (
    props: NotificationPreferencesProps
) => {
    const { onCheckedChange, notificationPreferences } = props
    return (
        <Box maxWidth="600px">
            <Flex direction="column" align="start" gap="1">
                <Label.Root htmlFor={'Notification Preferences'}>
                    <Flex align="center" gap="2">
                        Notification Preferences
                        <Tooltip
                            content={'Choose your notification preferences'}
                        >
                            <InfoCircledIcon />
                        </Tooltip>
                    </Flex>
                </Label.Root>
                <Box className="w-full">
                    <Flex
                        justify="start"
                        align="center"
                        wrap="wrap"
                        gap="6"
                        className="h-[42px]"
                    >
                        <Flex align="center" gap="1">
                            <Checkbox
                                key={'notifyOnPriceChange'}
                                name={'notifyOnPriceChange'}
                                variant="soft"
                                value={'notifyOnPriceChange'}
                                defaultChecked={
                                    notificationPreferences.notifyOnPriceChange
                                }
                                onCheckedChange={(checked) =>
                                    onCheckedChange &&
                                    onCheckedChange(
                                        checked as boolean,
                                        'notifyOnPriceChange'
                                    )
                                }
                            />
                            <Text>{'Notify on price change'}</Text>
                        </Flex>
                        <Flex align="center" gap="1">
                            <Checkbox
                                key={'notifyOnTargetPrice'}
                                name={'notifyOnTargetPrice'}
                                variant="soft"
                                value={'notifyOnTargetPrice'}
                                defaultChecked={true}
                                onCheckedChange={(checked) =>
                                    onCheckedChange &&
                                    onCheckedChange(
                                        checked as boolean,
                                        'notifyOnTargetPrice'
                                    )
                                }
                            />
                            <Text>{'Notify on target price'}</Text>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>
        </Box>
    )
}

export default NotificationPreferences

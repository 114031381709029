import React from 'react'
import { Checkbox } from '@radix-ui/themes'
import { Flex, Box, Text } from '@radix-ui/themes'

interface CheckboxCardProps {
    icon: React.ReactNode
    name: string
    value: string
    defaultChecked?: boolean | 'indeterminate'
    onCheckedChange?: (checked: boolean, value: string) => void
}

const CheckboxCard: React.FC<CheckboxCardProps> = ({
    icon,
    name,
    value,
    defaultChecked = false,
    onCheckedChange,
}) => {
    const [_checked, setChecked] = React.useState<boolean | 'indeterminate'>(
        defaultChecked
    )

    return (
        <Flex
            align="center"
            gap="8"
            className="py-2 px-3 border rounded-lg cursor-pointer"
            onClick={() => {
                setChecked((prevIsChecked) =>
                    prevIsChecked === 'indeterminate' ? true : !prevIsChecked
                )

                onCheckedChange && onCheckedChange(!_checked, value)
            }}
        >
            <Flex align="center" gap="1">
                {icon}
                <Text>{name}</Text>
            </Flex>
            <Box>
                <Checkbox
                    name={name}
                    value={value}
                    variant="soft"
                    checked={_checked}
                    className="flex items-center justify-center"
                />
            </Box>
        </Flex>
    )
}

export default CheckboxCard

import {
    Button,
    Grid,
    Flex,
    Heading,
    Text,
    Skeleton,
    Box,
    Dialog,
    IconButton,
} from '@radix-ui/themes'
import { FC, useEffect, useState } from 'react'
import { Cross1Icon, PlusIcon } from '@radix-ui/react-icons'
import TrackerCard from './TrackerCard'
import { CreateTrackerForm } from './CreateTrackerForm'
import { useQuery } from 'react-query'
import { useLoginDialog, useToast } from '../../shared/hooks'
import { useRecoilState, useRecoilValue } from 'recoil'
import { trackersAtom, userAtom } from '../../shared/atoms'
import Cookies from 'js-cookie'
import { UserTrackers } from '../../shared/types'
import { useNavigate } from 'react-router-dom'
import ReactGA from 'react-ga4'

interface TrackersProps {}

const Trackers: FC<TrackersProps> = () => {
    ReactGA.send({
        hitType: 'pageview',
        page: '/trackers',
        title: 'Trackers',
    })
    const [isTrackerFormOpen, setIsTrackerFormOpen] = useState(false)
    const [trackers, setTrackers] = useRecoilState<UserTrackers[]>(trackersAtom)
    const { setToast } = useToast()
    const [trackersLoaded, setTrackersLoaded] = useState(false)
    const user = useRecoilValue(userAtom)
    const navigate = useNavigate()
    const { openLoginDialog } = useLoginDialog()

    const canUserCreateTracker =
        trackers?.length < (user?.privileges?.numberOfTrackers || 0)

    // useEffect(() => {
    //     // Load trackers from cookies when the component mounts
    //     const savedTrackers = Cookies.get('trackers')

    //     if (!!savedTrackers && Array.isArray(JSON.parse(savedTrackers))) {
    //         console.log('savedTrackers', JSON.parse(savedTrackers))
    //         setTrackers(JSON.parse(savedTrackers))
    //     }
    //     setTrackersLoaded(true)
    // }, [setTrackers])

    // useEffect(() => {
    //     // Save trackers to cookies whenever they change
    //     // Set the TTL to 24 hours
    //     if (trackers.length)
    //         Cookies.set('trackers', JSON.stringify(trackers), { expires: 1 })
    // }, [trackers])

    const { isLoading } = useQuery(
        'userTrackers',
        async () => {
            const res = await fetch('/api/v1/users/trackers', {
                credentials: 'include',
            })
            if (!res.ok) throw new Error('Network response was not ok')
            return res.json()
            // TODO show error from api if its safe
        },
        {
            onError: (error: any) => {
                setTrackers([])
                setToast({
                    title: 'Error getting trackers',
                    message: error.message,
                    type: 'error',
                })
            },
            onSuccess: (data) => {
                setTrackers(data)
            },
            enabled:
                // !!user?.isAuthenticated && trackersLoaded && !trackers.length,
                !!user?.isAuthenticated,
        }
    )
    return (
        <Flex
            justify="start"
            align="start"
            className="mx-4 my-16 sm:mx-6 sm:my-6"
        >
            <Flex direction="column" className="w-full">
                <Flex direction="column" gap="5">
                    <Flex
                        direction="column"
                        gap="2"
                        className="w-full lg:w-[1000px]"
                    >
                        <Heading size="5" weight="medium">
                            Trackers
                        </Heading>
                        <Flex direction="column">
                            <Text weight="regular">
                                Welcome to the Trackers Page! Here, you can
                                create and manage price trackers for your
                                favorite products from various websites.
                            </Text>
                            <Text weight="regular">
                                You can create a tracker by clicking on the{' '}
                                <strong>create tracker</strong> button below.
                            </Text>
                            <Text weight="regular" className=" hidden sm:block">
                                After creating a tracker, you can view it here.
                                You can also <strong>edit</strong> some of the
                                tracker fields like notification channels or the
                                target price. And if you no longer need the
                                tracker, you can delete or unsubscribe to it.
                            </Text>
                            <Text weight="regular" className=" hidden sm:block">
                                You can also view the{' '}
                                <strong>price history</strong> of the product
                                you are tracking. The price history will show
                                you the price of the product over time. Click on
                                the tracker card to do so.
                            </Text>
                        </Flex>
                    </Flex>
                    <Button
                        className={`w-full sm:w-fit justify-center sm:justify-start ${
                            !canUserCreateTracker && 'cursor-not-allowed'
                        }`}
                        disabled={!canUserCreateTracker}
                        onClick={() => {
                            if (canUserCreateTracker) {
                                setIsTrackerFormOpen(true)
                            } else {
                                if (!user?.isAuthenticated) {
                                    setToast({
                                        title: 'Cannot create tracker',
                                        message: `You need to be logged in to create a tracker.`,
                                        type: 'error',
                                    })
                                    openLoginDialog()
                                } else {
                                    setToast({
                                        title: 'Cannot create tracker',
                                        message: `You don't have enough privileges to create a tracker. Please upgrade your account.`,
                                        type: 'error',
                                    })
                                    navigate('/prices')
                                }
                            }
                        }}
                    >
                        <PlusIcon /> Create Tracker
                    </Button>
                </Flex>
                <Grid
                    className="mt-4"
                    gap="4"
                    columns={{ initial: '1', sm: '2', lg: '3' }}
                    justify="start"
                    align="start"
                    width="100%"
                >
                    {isLoading
                        ? Array.from({ length: 4 }).map((_, index) => (
                              <Skeleton key={index}>
                                  <Box key={index}>
                                      <TrackerCard
                                          id="1234"
                                          url="https://example.url/product"
                                          imgUrl=""
                                          initialPrice={100}
                                          currentPrice={90}
                                          state="success"
                                          notificationChannels={[]}
                                          lastUpdatedAt="2022-01-01"
                                      />
                                  </Box>
                              </Skeleton>
                          ))
                        : trackers?.map((tracker, index) => (
                              <Box key={tracker.trackerId}>
                                  <TrackerCard
                                      id={tracker.trackerId}
                                      url={tracker.url}
                                      name={tracker.name}
                                      imgUrl={''} // Still not implemented
                                      initialPrice={tracker.initialPrice}
                                      currentPrice={tracker.currentPrice}
                                      state={
                                          index >=
                                          (user?.privileges?.numberOfTrackers ||
                                              0)
                                              ? 'notAllowed'
                                              : 'success'
                                      } // TODO Fail still not implemented
                                      notificationChannels={
                                          tracker.notificationChannels
                                      }
                                      lastUpdatedAt={tracker.lastUpdatedAt}
                                  />
                              </Box>
                          ))}
                </Grid>
            </Flex>
            {isTrackerFormOpen && (
                <Dialog.Root
                    open={isTrackerFormOpen}
                    onOpenChange={() => setIsTrackerFormOpen((prev) => !prev)}
                >
                    <Dialog.Content
                        maxWidth="450px"
                        className="overflow-visible"
                    >
                        <CreateTrackerForm
                            onSuccess={(newTracker) => {
                                setIsTrackerFormOpen((prev) => !prev)
                                setTrackers((prev) => [...prev, newTracker])
                            }}
                        />
                        {/* // TODO refresh trackers state to include new one */}
                        <Dialog.Close className="absolute top-0 right-0 z-20 translate-x-1/4 -translate-y-1/4 bg-[var(--gray-3)] opacity-95 shadow-md text-[var(--gray-12)]">
                            <IconButton
                                variant="ghost"
                                radius="full"
                                className="color-black"
                            >
                                <Cross1Icon />
                            </IconButton>
                        </Dialog.Close>
                    </Dialog.Content>
                </Dialog.Root>
            )}
        </Flex>
    )
}

export default Trackers

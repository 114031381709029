import { FC } from 'react'
import { Text, Flex } from '@radix-ui/themes'
import { CrossCircledIcon } from '@radix-ui/react-icons'
import { useFormContext } from 'react-hook-form'

interface FormErrorMessageProps {
    field: string
}

const FormErrorMessage: FC<FormErrorMessageProps> = (
    props: FormErrorMessageProps
) => {
    const { field } = props
    const {
        formState: { errors },
    } = useFormContext()
    const message = errors[field]?.message as string
    if (!message) return null
    return (
        <Flex gap="1" className="my-1">
            <CrossCircledIcon color="#CE2C31" className=" shrink-0" />
            <Text size="1" color="red" weight="medium">
                {message}
            </Text>
        </Flex>
    )
}

export default FormErrorMessage

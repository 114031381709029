import { FC, useEffect } from 'react'
import { Flex, Box, Button, Badge } from '@radix-ui/themes'
import * as Label from '@radix-ui/react-label'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { CreateTrackerSimpleFormSchema } from '../../../utils/validators'
import { useRecoilValue } from 'recoil'
import { useMutation } from 'react-query'

import FormInput from '../../../shared/components/Form/Input/FormInput'
import FormErrorMessage from '../../../shared/components/Form/FormErrorMessage'
import { useLoginDialog, useToast } from '../../../shared/hooks'
import { userAtom } from '../../../shared/atoms'
import { UserTrackers } from '../../../shared/types'
import { Tooltip } from '../../../shared/components/Tooltip/Tooltip'

interface CreateTrackerSimpleFormProps {
    onSuccess?: (data: any) => void
}

type CreateTrackerSimpleFormInputs = {
    productUrl: string
    currentPrice: string
}

const CreateTrackerSimpleForm: FC<CreateTrackerSimpleFormProps> = (
    props: CreateTrackerSimpleFormProps
) => {
    const { onSuccess } = props
    const { openLoginDialog } = useLoginDialog()
    const { setToast } = useToast()
    const user = useRecoilValue(userAtom)

    const canUserCreateTracker =
        (user?.trackers?.length || 0) <
        (user?.privileges?.numberOfTrackers || 0)

    const formMethods = useForm<CreateTrackerSimpleFormInputs>({
        resolver: zodResolver(CreateTrackerSimpleFormSchema),
        reValidateMode: 'onChange',
        defaultValues: {
            productUrl: localStorage.getItem('productUrl') || '',
            currentPrice: localStorage.getItem('currentPrice') || '',
        },
    })

    const {
        handleSubmit,
        watch,
        formState: { errors },
        reset,
        setError,
    } = formMethods

    const productUrl = watch('productUrl')
    const currentPrice = watch('currentPrice')

    useEffect(() => {
        localStorage.setItem('productUrl', productUrl)
        localStorage.setItem('currentPrice', currentPrice.toString())
    }, [productUrl, currentPrice])

    const createTrackerMutation = useMutation(
        ({ productUrl, currentPrice }: CreateTrackerSimpleFormInputs) =>
            fetch('/api/v1/trackers/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    credentials: 'include',
                },
                body: JSON.stringify({
                    url: productUrl,
                    expectedPrice: currentPrice,
                }),
            }).then(async (res) => {
                if (!res.ok) {
                    const errorData = await res.json()
                    throw new Error(
                        errorData?.body?.message || 'Failed to create tracker'
                    )
                }
                return res.json()
            }),
        {
            onError: (error: any) => {
                reset({ productUrl, currentPrice }, { keepSubmitCount: true })
                setToast({
                    type: 'error',
                    title: 'Failed to create tracker',
                    message: error.message,
                    duration: 4000,
                })
                setError('root', {
                    type: 'manual',
                    message: error.message || error,
                })
            },
            onSuccess: (data) => {
                setToast({
                    type: 'success',
                    title: 'Tracker created',
                    message: 'Tracker has been created successfully',
                    duration: 4000,
                })
                // Reset form
                reset(
                    { productUrl: '', currentPrice: '' },
                    { keepSubmitCount: true }
                )
                localStorage.removeItem('productUrl')
                localStorage.removeItem('currentPrice')
                const userTracker: UserTrackers = {
                    trackerId: data._id,
                    url: data.url,
                    currentPrice: data.expectedPrice,
                    targetPrice: 0,
                    initialPrice: data.expectedPrice,
                    notificationChannels: ['email'],
                    notifyOnPriceChange: true,
                    notifyOnTargetReached: true,
                    lastUpdatedAt: new Date().toISOString(),
                }
                // Propagate success
                if (onSuccess) {
                    onSuccess(userTracker)
                }
            },
        }
    )

    const onSubmit: SubmitHandler<CreateTrackerSimpleFormInputs> = (data) => {
        if (!user?.isAuthenticated) {
            setToast({
                type: 'error',
                title: 'User not authenticated',
                message: 'Please login to create a tracker',
                duration: 4000,
            })
            openLoginDialog()
        } else if (!canUserCreateTracker) {
            setToast({
                type: 'error',
                title: 'Cannot create tracker',
                message: 'Please upgrade your account.',
                duration: 4000,
            })
            // Scroll into view section with id #Pricing
            document.getElementById('Pricing')?.scrollIntoView()
        } else {
            createTrackerMutation.mutate(data)
        }
    }

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <Flex
                    direction="column"
                    gap="3"
                    justify="center"
                    align="center"
                    width="100%"
                >
                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className="w-full"
                    >
                        <Label.Root htmlFor="Product Link">
                            <Flex align="center" gap="2">
                                Product Link{' '}
                                <Tooltip content="Write the url of the product you want to track here.">
                                    <InfoCircledIcon />
                                </Tooltip>
                            </Flex>
                        </Label.Root>
                        <FormInput
                            name="productUrl"
                            size="3"
                            placeholder="https://www.myproduct/url/product"
                            variant="surface"
                            defaultValue={
                                localStorage.getItem('productUrl') || ''
                            }
                            className="w-full"
                        />
                        <FormErrorMessage field="productUrl" />
                    </Flex>

                    <Flex
                        direction="column"
                        justify="start"
                        align="start"
                        className={`w-full transition-all duration-300 delay-300 ease-in-out  ${
                            productUrl
                                ? 'opacity-100'
                                : 'opacity-0 h-0 overflow-hidden'
                        }`}
                    >
                        <Label.Root htmlFor="Product Link">
                            <Flex align="center" gap="2">
                                Current Product Price{' '}
                                <Tooltip content="Write the current price of the product you want to track here. This helps us locating it.">
                                    <InfoCircledIcon />
                                </Tooltip>
                            </Flex>
                        </Label.Root>
                        <FormInput
                            name="currentPrice"
                            size="3"
                            placeholder="00.00"
                            variant="surface"
                            defaultValue={
                                localStorage.getItem('currentPrice') || ''
                            }
                            className="w-full"
                            onInput={(e) => {
                                e.currentTarget.value =
                                    e.currentTarget.value.replace(
                                        /[^0-9.]/g,
                                        ''
                                    )
                            }}
                        />
                        <FormErrorMessage field="currentPrice" />
                    </Flex>

                    <Box className="w-2/3 m-4">
                        <Flex align="center" gap="2">
                            <Button
                                variant="soft"
                                loading={createTrackerMutation.isLoading}
                                size="3"
                                color={
                                    errors.root
                                        ? 'red'
                                        : createTrackerMutation.isSuccess
                                        ? 'green'
                                        : 'indigo'
                                }
                                className={`w-full ${
                                    productUrl && currentPrice
                                        ? 'cursor-pointer'
                                        : 'cursor-not-allowed'
                                }`}
                                disabled={!productUrl || !currentPrice}
                            >
                                Create Tracker
                            </Button>
                            <Badge color="blue" variant="soft">
                                BETA
                            </Badge>
                        </Flex>
                        <FormErrorMessage field="root" />
                    </Box>
                </Flex>
            </form>
        </FormProvider>
    )
}

export default CreateTrackerSimpleForm

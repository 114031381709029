import { Flex, Text, Checkbox, Box } from '@radix-ui/themes'
import * as Label from '@radix-ui/react-label'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { CheckedState } from '@radix-ui/react-checkbox'
import { Tooltip } from '../../Tooltip'

type FormCheckboxGroupOptions = {
    label: string
    value: string
    disabled?: boolean
}

interface FormCheckboxGroupProps {
    label: string
    tooltip: string
    name: string
    variant: 'surface' | 'classic' | 'soft' | undefined
    className?: string
    defaultValue: string[]
    options: FormCheckboxGroupOptions[]
}

const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
    label,
    tooltip,
    name,
    variant,
    className = 'w-full',
    defaultValue,
    options,
}) => {
    const { setValue } = useFormContext()
    const onCheckedChange = (checked: CheckedState, name: string) => {
        setValue(name, checked)
    }
    return (
        <Flex direction="column" align="start" gap="1" className={className}>
            <Label.Root htmlFor={name}>
                <Flex align="center" gap="2">
                    {label}{' '}
                    <Tooltip content={tooltip}>
                        <InfoCircledIcon />
                    </Tooltip>
                </Flex>
            </Label.Root>
            <Box className={className}>
                <Flex justify="between" align="center" wrap="wrap">
                    {options.map((option) => (
                        <Flex align="center" gap="1" key={option.value}>
                            <Checkbox
                                name={option.value}
                                variant={variant}
                                value={option.value}
                                defaultChecked={defaultValue.includes(
                                    option.value
                                )}
                                onCheckedChange={(checked) =>
                                    onCheckedChange(checked, option.value)
                                }
                            />
                            <Text>{option.label}</Text>
                        </Flex>
                    ))}
                </Flex>
            </Box>
        </Flex>
    )
}

export default FormCheckboxGroup

import {
    Avatar,
    Box,
    Flex,
    Link,
    Separator,
    Skeleton,
    Table,
    Text,
    TextField,
} from '@radix-ui/themes'
import { FC, useEffect, useState } from 'react'
import * as Label from '@radix-ui/react-label'
import { ArrowBottomRightIcon, ArrowTopRightIcon } from '@radix-ui/react-icons'
import PriceHistoryChart from './PriceHistoryChart'
import React from 'react'
import { useParams } from 'react-router-dom'
import { TrackerDropdown } from '../../shared/components/TrackerDropdown'
import NotificationPreferences from './NotificationPreferences'
import NotificationChannels from './NotificationChannels'
import { useMutation, useQuery } from 'react-query'
import { useToast } from '../../shared/hooks'
import { NotificationChannel } from '../../shared/types'
import { trackersAtom } from '../../shared/atoms'
import { useSetRecoilState } from 'recoil'
import { NivoChart } from './NivoChart'
import ReactGA from 'react-ga4'
import { Tooltip } from '../../shared/components/Tooltip'

type Tracker = {
    trackerId: string
    name?: string
    imgUrl?: string
    notificationChannels: NotificationChannel[]
    notifyOnPriceChange: boolean
    notifyOnTargetReached: boolean
    history?: { timestamp: Date; price: number }[]
    targetPrice: number
    initialPrice: number
    currentPrice: number
    url: string
    lastUpdatedAt: Date
}

const Tracker: FC = () => {
    const { setToast } = useToast()
    const setTrackers = useSetRecoilState(trackersAtom)
    const params = useParams()
    const id = params?.id

    ReactGA.send({
        hitType: 'pageview',
        page: `/trackers/${id}`,
        title: 'Tracker',
    })

    const [tracker, setTracker] = useState<Tracker>()
    // const data = [
    //     {
    //         id: 'Price',
    //         color: '#3E63DD',
    //         data:
    //             tracker?.history?.map((historyItem) => ({
    //                 x: historyItem.timestamp,
    //                 y: historyItem.price,
    //             })) || [],
    //     },
    // ]
    const priceChange =
        tracker &&
        ((tracker?.currentPrice - tracker?.initialPrice) /
            tracker?.initialPrice) *
            100
    const isPriceIncreased = priceChange && priceChange > 0

    const [trackerName, setTrackerName] = useState<string>('')
    const [initialPrice, setInitialPrice] = useState<number>(0)
    const [targetPrice, setTargetPrice] = useState<number>(0)
    const lowestPrice = tracker?.history?.reduce((acc, curr) => {
        return acc.price < curr.price ? acc : curr
    })
    const highestPrice = tracker?.history?.reduce((acc, curr) => {
        return acc.price > curr.price ? acc : curr
    })

    const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)

    const updateTrackerMutation = useMutation(
        (updatedFields: Partial<Tracker>) =>
            fetch(`/api/v1/users/trackers/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedFields),
            }).then(async (res) => {
                const jsonRes = await res.json()
                if (!res.ok) {
                    if (jsonRes.message) {
                        throw new Error(jsonRes.message)
                    }
                    throw new Error('Network response was not ok')
                }
                return jsonRes
            }),
        {
            onSuccess: (data) => {
                console.log(data)
                setTrackers((prevTrackers) => {
                    const updatedTrackers = prevTrackers.map((tracker) => {
                        if (tracker.trackerId === id) {
                            return { ...tracker, ...data }
                        }
                        return tracker
                    })
                    return updatedTrackers
                })
                setToast({
                    title: 'Tracker updated',
                    type: 'success',
                    duration: 3000,
                })
            },
            onError: () => {
                setToast({
                    title: 'Failed to update tracker',
                    type: 'error',
                    duration: 3000,
                })
            },
        }
    )

    useEffect(() => {
        const debounceUpdate = () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }

            timeoutRef.current = setTimeout(() => {
                const updatedFields: Partial<Tracker> = {}

                if (trackerName !== tracker?.name) {
                    updatedFields.name = trackerName
                }
                if (initialPrice !== tracker?.initialPrice) {
                    updatedFields.initialPrice = initialPrice
                }
                if (targetPrice !== tracker?.targetPrice) {
                    updatedFields.targetPrice = targetPrice
                }

                if (Object.keys(updatedFields).length > 0) {
                    updateTrackerMutation.mutate(updatedFields)
                }
            }, 2000)
        }

        debounceUpdate()

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
            }
        }
    }, [trackerName, initialPrice, targetPrice, tracker]) // TODO test

    const { isLoading } = useQuery(
        'tracker',
        async () => {
            const res = await fetch(
                `/api/v1/users/trackers/${id}?history=true`,
                {
                    credentials: 'include',
                }
            )
            if (!res.ok) throw new Error('Could not retrive price history data')
            return res.json()
            // TODO show error from api if its safe
        },
        {
            onError: (error: any) => {
                // setTracker({})
                setToast({
                    title: 'Error getting tracker',
                    message: error.message,
                    type: 'error',
                })
            },
            onSuccess: (data: Tracker) => {
                console.log(data)
                setTracker(data)
                setTrackerName(
                    data.name ??
                        (data.url && new URL(data.url).hostname) ??
                        'Tracker Name'
                )
                setInitialPrice(data.initialPrice ?? 0)
                setTargetPrice(data.targetPrice ?? 0)
            },
        }
    )
    return (
        <Flex
            justify="start"
            align="start"
            className="mx-4 my-16 sm:my-4 w-full"
        >
            <Flex direction="column" className="w-full">
                <Flex gap="2" className="mb-6">
                    <Link href="/Trackers">Trackers</Link> <span>/</span>
                    <Link href={`/Trackers/${tracker?.trackerId}`}>
                        {tracker?.name}
                    </Link>
                </Flex>
                <Flex direction="column" gap="5">
                    <Flex justify="between" className="gap-4">
                        <Flex
                            align="center"
                            gap="3"
                            className="flex-wrap w-full"
                        >
                            <Skeleton loading={isLoading}>
                                <Avatar
                                    size="5"
                                    fallback={
                                        (trackerName &&
                                            trackerName.charAt(0)) ||
                                        'T'
                                    }
                                    src={tracker?.imgUrl}
                                />
                            </Skeleton>
                            <Skeleton loading={isLoading}>
                                <Box className="w-full lg:p-0 sm:max-w-52">
                                    <TextField.Root
                                        variant="surface"
                                        placeholder={trackerName}
                                        size="3"
                                        value={trackerName}
                                        onChange={(e) =>
                                            setTrackerName(e.target.value)
                                        }
                                    />
                                </Box>
                            </Skeleton>
                        </Flex>
                        {!!id && (
                            <TrackerDropdown
                                id={id}
                                currentPrice={tracker?.currentPrice}
                            />
                        )}
                    </Flex>
                    <Skeleton loading={isLoading} width="150px" height="40px">
                        <Flex align="center" gap="2">
                            <Text size="5" weight="bold">
                                💰 {tracker?.currentPrice.toFixed(2)}
                            </Text>
                            {isPriceIncreased ? (
                                <ArrowTopRightIcon color="red" />
                            ) : (
                                <ArrowBottomRightIcon color="green" />
                            )}
                            <Text
                                size="2"
                                weight="regular"
                                color={isPriceIncreased ? 'red' : 'green'}
                            >
                                {`${isPriceIncreased ? '+' : '-'}${Math.abs(
                                    priceChange ?? 0
                                ).toFixed(1)}%`}
                            </Text>
                        </Flex>
                    </Skeleton>

                    <Skeleton loading={isLoading} height="40px">
                        <Box className="w-full max-w-52 lg:p-0 sm:max-w-md md:max-w-lg lg:max-w-screen-lg">
                            <Tooltip content={tracker?.url}>
                                <Link
                                    size="3"
                                    weight="medium"
                                    href={tracker?.url}
                                    truncate={true}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block"
                                >
                                    {tracker?.url}
                                </Link>
                            </Tooltip>
                        </Box>
                    </Skeleton>
                </Flex>
                <Flex direction="column" className="gap-4 w-full">
                    <Flex justify="start" className="flex-wrap mb-8">
                        <Skeleton
                            loading={isLoading}
                            height="400px"
                            className="mt-4"
                        >
                            {!isLoading && (
                                <Box className="w-full lg:max-w-[950px]">
                                    <PriceHistoryChart
                                        priceHistory={tracker?.history ?? []}
                                        initialPrice={initialPrice || 0}
                                        targetPrice={targetPrice || 0}
                                    />
                                </Box>
                            )}
                        </Skeleton>
                        <Flex
                            justify="start"
                            gap="1"
                            className="flex-wrap w-full md:max-w-96 max-h-80"
                        >
                            <Skeleton loading={isLoading}>
                                <Box className="w-full md:max-w-96">
                                    <Table.Root>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.ColumnHeaderCell></Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>
                                                    Price
                                                </Table.ColumnHeaderCell>
                                                <Table.ColumnHeaderCell>
                                                    Date
                                                </Table.ColumnHeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.RowHeaderCell>
                                                    Highest Price
                                                </Table.RowHeaderCell>
                                                <Table.Cell>
                                                    <strong>
                                                        {highestPrice?.price?.toFixed(
                                                            2
                                                        ) ?? 0}
                                                    </strong>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {(highestPrice?.timestamp &&
                                                        new Date(
                                                            highestPrice.timestamp
                                                        ).toLocaleDateString(
                                                            'en-GB'
                                                        )) ??
                                                        'N/A'}
                                                </Table.Cell>
                                            </Table.Row>
                                            <Table.Row>
                                                <Table.RowHeaderCell>
                                                    Lowest Price
                                                </Table.RowHeaderCell>
                                                <Table.Cell>
                                                    <strong>
                                                        {lowestPrice?.price?.toFixed(
                                                            2
                                                        ) ?? 0}
                                                    </strong>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    {(lowestPrice?.timestamp &&
                                                        new Date(
                                                            lowestPrice.timestamp
                                                        ).toLocaleDateString(
                                                            'en-GB'
                                                        )) ??
                                                        'N/A'}
                                                </Table.Cell>
                                            </Table.Row>
                                        </Table.Body>
                                    </Table.Root>
                                </Box>
                            </Skeleton>
                            <Flex gap="3" direction="column">
                                <Skeleton loading={isLoading} height="40px">
                                    <Box className=" md:max-w-64">
                                        <Label.Root>Initial Price</Label.Root>
                                        <TextField.Root
                                            variant="surface"
                                            placeholder="Initial Price"
                                            size="3"
                                            value={
                                                (initialPrice &&
                                                    initialPrice.toFixed(2)) ||
                                                0
                                            }
                                            onChange={(e) =>
                                                setInitialPrice(
                                                    parseFloat(e.target.value)
                                                )
                                            }
                                        />
                                    </Box>
                                </Skeleton>
                                <Skeleton loading={isLoading} height="40px">
                                    <Box className=" md:max-w-64">
                                        <Label.Root>Target Price</Label.Root>
                                        <TextField.Root
                                            variant="surface"
                                            placeholder="Target Price"
                                            size="3"
                                            value={
                                                (targetPrice &&
                                                    targetPrice.toFixed(2)) ||
                                                0
                                            }
                                            onChange={(e) =>
                                                setTargetPrice(
                                                    parseFloat(e.target.value)
                                                )
                                            }
                                        />
                                    </Box>
                                </Skeleton>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex gap="7" className="flex-wrap">
                        <Skeleton
                            loading={isLoading}
                            width="160px"
                            height="60px"
                        >
                            {!isLoading && tracker && (
                                <NotificationChannels
                                    id={id}
                                    activeChannels={
                                        tracker?.notificationChannels ?? []
                                    }
                                    onCheckedChange={(checked, value) => {
                                        console.log(checked, value)
                                        const updatedFields: Partial<Tracker> =
                                            {}
                                        const notificationChannels =
                                            tracker?.notificationChannels ?? []
                                        if (checked) {
                                            updatedFields.notificationChannels =
                                                [...notificationChannels, value]
                                        } else {
                                            updatedFields.notificationChannels =
                                                notificationChannels.filter(
                                                    (channel) =>
                                                        channel !== value
                                                )
                                        }
                                        updateTrackerMutation.mutate(
                                            updatedFields
                                        )
                                    }}
                                />
                            )}
                        </Skeleton>
                        <Separator orientation="vertical" className="h-20" />
                        <Skeleton
                            loading={isLoading}
                            width="500px"
                            height="40px"
                        >
                            {!isLoading && tracker && (
                                <NotificationPreferences
                                    notificationPreferences={{
                                        notifyOnPriceChange:
                                            tracker?.notifyOnPriceChange ||
                                            false,
                                        notifyOnTargetPrice:
                                            tracker?.notifyOnTargetReached ||
                                            false,
                                    }}
                                    onCheckedChange={(checked, value) => {
                                        const updatedFields: Partial<Tracker> =
                                            {}
                                        if (value === 'notifyOnPriceChange') {
                                            updatedFields.notifyOnPriceChange =
                                                checked
                                        } else if (
                                            value === 'notifyOnTargetPrice'
                                        ) {
                                            updatedFields.notifyOnTargetReached =
                                                checked
                                        }
                                        updateTrackerMutation.mutate(
                                            updatedFields
                                        )
                                    }}
                                />
                            )}
                        </Skeleton>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}

export default Tracker
